<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ valid }"
      class="ml-auto"
    >
      <!-- Existing/New Holder Form Section -->
      <holders-selection-form
        v-model="holder"
        :title="'Select holder'"
        :holders-list="holdersList"
        @update="updateHolderId"
      />

      <!-- convertible Details Section -->
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Convertible term'"
            :rules="{ required: true }"
          >
            <common-input-select
              v-model="formData.convertible_terms_id"
              label="Convertible Term"
              placeholder="Select convertible term"
              :items="convertibleTermsList"
              item-text="name"
              item-value="id"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              @change="setPrefilledData"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Financing round'"
            :rules="{ required: true }"
          >
            <common-input-select
              v-model="formData.convertibleFinancingRoundId"
              label="Financing Round"
              placeholder="Select financing round"
              :items="financingRoundList"
              :hide-details="errors.length === 0"
              item-text="name"
              item-value="id"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Invested Capital'"
            :rules="{ required: true, min_value: 0 }"
          >
            <common-input-currency-input
              v-model="formData.amount_invested"
              label="Invested Capital"
              color="primary"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              :options="{ locale: 'en-US', precision: 2 }"
              prepend-inner-icon="mdi-currency-usd"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Stock registration name'"
            :rules="{ required: true }"
          >
            <common-input-text-field
              v-model="stockRegistrationName"
              label="Stock Registration Name"
              color="primary"
              :error-messages="errors"
              :hide-details="errors.length === 0"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Grant date'"
            :rules="{ required: true }"
          >
            <input-template-date
              v-model="grantDate"
              :mask="'##/##/####'"
              :input-templ="{label: 'Grant date', mandatory: true}"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              clearable
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Maturation date'"
            :rules="{ required: true }"
          >
            <input-template-date
              v-model="maturedDate"
              :mask="'##/##/####'"
              :input-templ="{label: 'Maturation date', mandatory: true}"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              clearable
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Board approval date'"
            :rules="{ required: true }"
          >
            <input-template-date
              v-model="boardApprovedOn"
              :mask="'##/##/####'"
              :input-templ="{label: 'Board approval date', mandatory: true}"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              clearable
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <notes-and-documents-form
            :id="convertibleDetails.issuedConvertibleId"
            :type="CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES"
            :internal-dialog="internalDialog"
            :mode="'edit'"
            :is-submit="isSubmit"
            @navToList="navToList"
          />
        </v-col>
      </v-row>

      <!-- Form Buttons Section -->
      <v-row>
        <v-col>
          <v-divider />
          <div class="mt-2 d-flex justify-space-between">
            <common-trs-btn
              type="secondary"
              medium
              class="mr-2"
              text
              @click="$emit('close')"
            >
              Cancel
            </common-trs-btn>
            <common-trs-btn
              type="primary"
              medium
              class="white--text"
              text
              :disabled="!valid"
              :loading="loading"
              @click="save"
            >
              Save
            </common-trs-btn>
          </div>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'
  import HoldersSelectionForm from '@/components/common/captable/components/forms/HoldersSelectionForm'
  import NotesAndDocumentsForm from '@/components/common/captable/components/forms/NotesAndDocumentsForm.vue'

  import * as captableService from '@/components/common/captable/services/captable'
  import * as captableAdminService from '@/services/captable-admin-service'

  export default {
    name: 'ModifyConvertible',

    components: {
      HoldersSelectionForm,
      InputTemplateDate,
      NotesAndDocumentsForm,
    },

    props: {
      convertibleDetails: {
        type: Object,
        required: true,
        default: () => {},
      },
      holdersList: {
        type: Array,
        required: true,
        default: () => [],
      },
      convertibleTermsList: {
        type: Array,
        required: true,
      },
      eipList: {
        type: Array,
        required: true,
      },
      financingRoundList: {
        type: Array,
        required: true,
      },
      internalDialog: {
        type: Boolean,
        default: false,
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      holder: {},
      formData: {},
      loading: false,
      grantDate: '',
      maturedDate: '',
      boardApprovedOn: '',
      isSubmit: false,
      stockRegistrationName: '',
    }),

    computed: {
      securityDetails () {
        return {
          name: this.convertibleDetails.convertibleName || '',
          numberOfconvertiblesGranted: this.convertibleDetails.number || 0,
        }
      },
      holderDetails () {
        return {
          name: this.convertibleDetails.holderName || '',
          email: this.convertibleDetails.email || '',
          id: this.convertibleDetails.holderId || '',
        }
      },
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      convertibleDetails: {
        handler (val) {
          if (val) {
            this.formData = JSON.parse(JSON.stringify(this.convertibleDetails))
            this.stockRegistrationName = this.formData.registration_name
            this.holder.to_holder_id = this.convertibleDetails.holder_id
            this.grantDate = moment(val.issued_on).format('MM/DD/YYYY')
            this.boardApprovedOn = moment(val.convertibleBoardApprovedOn).format('MM/DD/YYYY')
            this.maturedDate = moment(val.matures_on).format('MM/DD/YYYY')
          }
        },
        immediate: true,
      },
      grantDate: {
        handler (val) {
          if (val) {
            this.formData.issued_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      maturedDate: {
        handler (val) {
          if (val) {
            this.formData.matures_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      boardApprovedOn: {
        handler (val) {
          if (val) {
            this.formData.convertibleBoardApprovedOn = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      internalDialog: {
        handler (val) {
          if (val) {
            this.isSubmit = false
          } else {
            this.formData = JSON.parse(JSON.stringify(this.convertibleDetails))
            this.stockRegistrationName = this.formData.registration_name
          }
        },
      },
      stockRegistrationName: {
        handler (val) {
          if (val) {
            this.formData.registration_name = val
          }
        },
        immediate: true,
      },
    },

    methods: {
      updateHolderId (val) {
        if (val.to_holder_id) {
          this.formData.holder_id = val.to_holder_id
        } else {
          this.formData.holder_id = null
          delete val.to_holder_id
          this.holder = val
        }
      },
      async save () {
        this.loading = true
        if (!this.formData.holder_id) {
          this.formData.holder_id = await this.createNewInvestor()
        }
        const converibleData = {
          amount: this.formData.amount_invested,
          board_approved_on: this.formData.convertibleBoardApprovedOn,
          convertible_terms_id: this.formData.convertible_terms_id,
          financing_round_id: this.formData.convertibleFinancingRoundId,
          holder_id: this.formData.holder_id,
          issued_on: this.formData.issued_on,
          matures_on: this.formData.matures_on,
          registration_name: this.formData.registration_name,
          share_terms_id: this.formData.share_terms_id,

        }
        this.formData.number = parseInt(this.formData.number)
        this.formData.board_approved_on = this.formData.convertibleBoardApprovedOn
        try {
          this.clearNullData()
          const details = {
            payload: converibleData,
            certificatePayload: {
              captableId: this.captableId,
              securityId: this.convertibleDetails.issuedConvertibleId,
              termDocType: this.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLE_TERMS,
              securityDocType: this.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES,
              termID: this.convertibleDetails.convertible_terms_id,
              registrationName: this.formData.registration_name,
              issuedNumber: this.formData.number,
            },
          }
          if (this.convertibleDetails.is_issued && !this.isDraft) {
            await captableAdminService.createServiceRequest(this.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES, this.CONSTS.ISSUED_SECURITY_ACTIONS.MODIFY, this.captableId, this.convertibleDetails.issuedConvertibleId, details)
          } else {
            await captableAdminService.approveServiceRequest(this.CONSTS.ISSUED_SECURITY_ACTIONS.MODIFY, this.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES, this.captableId, details, this.isDraft)
          }
          this.isSubmit = true
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to modify convertible. Please try again later.',
          })
        }
      },
      navToList () {
        this.loading = false
        this.$refs.observer.reset()
        this.$emit('close')
        this.$emit('reload')
        this.formData = {}
        this.$store.dispatch('app/message', { text: this.convertibleDetails.is_issued ? captableAdminService.getServiceRequestSuccessMessage() : 'Convertible modified successfully.' })
      },
      async createNewInvestor () {
        try {
          const resp = await captableService.draftProfile(this.captableId, this.holder)
          const id = resp && resp.data.id
          await captableService.publishProfile(this.captableId, id)
          return id
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: `Sorry! Unable to transfer your Convertible to ${this.holder.name}. Please try again later.`,
          })
        }
      },
      clearNullData () {
        Object.keys(this.formData).forEach((key) => {
          if (this.formData[key] === null || this.formData[key] === undefined) {
            delete this.formData[key]
          }
        })
      },
      setPrefilledData (val) {
        if (val) {
          const convertibleTerm = this.convertibleTermsList.find(term => term.id === val)
          if (convertibleTerm.convertible_type) {
            this.stockRegistrationName = `${this.prefix(convertibleTerm.convertible_type)}-`
          }
          if (convertibleTerm.board_approved_on) {
            this.boardApprovedOn = moment(convertibleTerm.board_approved_on).format('MM/DD/YYYY')
          } else {
            this.boardApprovedOn = ''
          }
        }
      },
      prefix (type) {
        if (this.type === this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE) {
          return this.CONSTS.SECURITIES_PREFIX.CONVERTIBLE
        } else if (type === this.CONSTS.CONVERTIBLE_TERM_TYPES.DEBT) {
          return this.CONSTS.SECURITIES_PREFIX.DEBT
        } else if (type === this.CONSTS.CONVERTIBLE_TERM_TYPES.SAFE) {
          return this.CONSTS.SECURITIES_PREFIX.SAFE
        }
      },
    },
  }
</script>
