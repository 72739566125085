<template>
  <v-container>
    <div class="text-center">
      <v-row>
        <v-col
          cols="12"
        >
          <search-bar
            v-model="search"
            :label="label"
          >
            <template v-slot:content>
              <v-row class="no-gutters">
                <v-col
                  cols="12"
                  md="4"
                  class="px-1"
                >
                  <common-input-select
                    v-model="filter.warrantTerms"
                    label="Warrant Terms"
                    placeholder="Select Warrant Terms"
                    :items="warrantTerms"
                    item-text="name"
                    item-value="id"
                    multiple
                    clearable
                  />
                </v-col>
                <v-col
                  v-if="showDraft"
                  cols="12"
                  md="4"
                  class="px-1"
                >
                  <common-input-select
                    v-model="filter.status"
                    label="Status"
                    placeholder="Status"
                    :items="CONSTS.DRAFTED_SECURITY_STATUS_LIST"
                    item-text="title"
                    item-value="value"
                    multiple
                    clearable
                  />
                </v-col>
                <v-col
                  v-if="!showDraft"
                  cols="12"
                  md="4"
                  class="px-1"
                >
                  <common-input-select
                    v-model="filter.financingRounds"
                    label="Financing Round"
                    placeholder="Select Financing Round"
                    :items="financingRounds"
                    item-text="name"
                    item-value="id"
                    multiple
                    clearable
                  />
                </v-col>
              </v-row>
            </template>
            <template v-slot:actions>
              <div
                v-if="showDraft"
                class="text-right"
              >
                <common-trs-btn
                  v-feature="CONSTS.CAPTABLE_FEATURES.ISSUE_SECURITY"
                  type="secondary"
                  medium
                  class="white--text mt-4 mr-3"
                  text
                  :disabled="!selectedWarrants || selectedWarrants.length == 0"
                  @click="issueWarrants"
                >
                  Issue Warrants
                </common-trs-btn>
                <common-trs-btn
                  v-feature="CONSTS.CAPTABLE_FEATURES.DRAFT_SECURITY"
                  type="primary"
                  medium
                  class="white--text mt-4"
                  text
                  :to="{ name: 'DraftWarrant' }"
                >
                  <v-icon class="mr-2">
                    mdi-plus-circle
                  </v-icon>
                  Draft Warrants
                </common-trs-btn>
              </div>
            </template>
          </search-bar>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col>
          <warrants-data-table
            :issueable-warrants="issueableWarrants"
            :warrant-terms="warrantTerms"
            :financing-rounds="financingRounds"
            :eips="eips"
            :share-terms-list="shareTermsList"
            :investors="investors"
            :search="search"
            :loading="loading"
            :is-selectable="showDraft"
            :hide-fields="hideFields"
            :filter="filter"
            @warrantsSelected="warrantsSelected"
            @stopLoading="$emit('stopLoading')"
            @delete="deleteWarrant"
            @reload="$emit('reload')"
            @issue="issue"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
  import searchBar from '@/components/common/captable/components/Search'
  import WarrantsDataTable from './../data-tables/WarrantsDataTable'

  export default {
    name: 'WarranstTab',

    components: {
      searchBar,
      WarrantsDataTable,
    },

    props: {
      hideFields: {
        validate: () => true,
        default: () => [],
      },
      issueableWarrants: {
        type: Array,
        required: true,
        default: () => [],
      },
      warrantTerms: {
        type: Array,
        required: true,
        default: () => [],
      },
      financingRounds: {
        type: Array,
        required: true,
        default: () => [],
      },
      eips: {
        type: Array,
        required: true,
        default: () => [],
      },
      investors: {
        type: Array,
        required: true,
        default: () => [],
      },
      loading: {
        type: Boolean,
        default: false,
      },
      showDraft: {
        type: Boolean,
        default: false,
      },
      clearFilters: {
        type: Boolean,
        default: false,
      },
      shareTermsList: {
        type: Array,
        required: true,
      },
    },

    data: () => ({
      label: 'Search',
      search: '',
      selectedWarrants: [],
      filter: {},
    }),

    watch: {
      clearFilters: {
        handler (val) {
          if (val) {
            this.filter = {}
            this.search = ''
          }
        },
        immediate: true,
      },
    },

    methods: {
      issueWarrants () {
        this.$emit('issueWarrant', this.selectedWarrants)
      },

      warrantsSelected (val) {
        this.selectedWarrants = val
      },
      deleteWarrant (val) {
        this.$emit('delete', val)
      },
      issue (val) {
        this.$emit('issue', val)
      },
    },
  }
</script>
