<template>
  <div class="container">
    <v-row class="modal-container">
      <v-col
        cols="12"
        class="header-container info-container"
      >
        <div class="d-flex">
          <v-col>
            <div class="text--lighten-1 font-weight-medium">
              {{ internalValue.holderName }}
            </div>
            <div class="trsText--text text-body-2 text--lighten-1 mt-1">
              {{ internalValue.email }}
            </div>
          </v-col>
          <v-col v-if="type !== CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES">
            <div class="text--lighten-1 font-weight-medium">
              {{ $number(internalValue.number) }}
            </div>
            <div class="trsText--text text-body-2 text--lighten-1 mt-1">
              {{ isDraft ? 'Granted':'No. of' }} {{ type }}
            </div>
          </v-col>
          <v-col v-if="type === CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES || type === CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES">
            <div class="text--lighten-1 font-weight-medium">
              {{ $currencyWithPrecision(internalValue.amount_invested ? internalValue.amount_invested : internalValue.price_per_share*internalValue.number ) }}
            </div>
            <div class="trsText--text text-body-2 text--lighten-1 mt-1">
              {{ isDraft ? 'Invested capital' : type === CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES ? 'Amount' : 'Invested capital' }}
            </div>
          </v-col>
          <v-col v-if="type === CONSTS.NOTES_DOCUMENT_API_TYPE.OPTIONS">
            <div class="text--lighten-1 font-weight-medium">
              {{ $currencyWithPrecision(internalValue.exercise_price) }}
            </div>
            <div class="trsText--text text-body-2 text--lighten-1 mt-1">
              Exercise price per share
            </div>
          </v-col>
          <v-col v-if="type === CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANTS">
            <div class="text--lighten-1 font-weight-medium">
              {{ $currencyWithPrecision(internalValue.excercisePrice) }}
            </div>
            <div class="trsText--text text-body-2 text--lighten-1 mt-1">
              Exercise price
            </div>
          </v-col>
          <v-col v-if="type === CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANTS">
            <div class="text--lighten-1 font-weight-medium">
              {{ $date(internalValue.expires_on) }}
            </div>
            <div class="trsText--text text-body-2 text--lighten-1 mt-1">
              Expiration date
            </div>
          </v-col>
          <v-col v-if="internalValue.type && type === CONSTS.NOTES_DOCUMENT_API_TYPE.OPTIONS">
            <div class="text--lighten-1 font-weight-medium">
              {{ getOptionActionType() }}
            </div>
            <div class="trsText--text text-body-2 text--lighten-1 mt-1">
              Option type
            </div>
          </v-col>
          <v-col v-if="type === CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES || type === CONSTS.NOTES_DOCUMENT_API_TYPE.OPTIONS">
            <div class="text--lighten-1 font-weight-medium">
              {{ $date(internalValue.granted_on ? internalValue.granted_on : internalValue.issued_on) }}
            </div>
            <div class="trsText--text text-body-2 text--lighten-1 mt-1">
              Grant date
            </div>
          </v-col>
          <v-col v-if="type === CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES">
            <div class="text--lighten-1 font-weight-medium">
              {{ $date(internalValue.convertibleBoardApprovedOn) }}
            </div>
            <div class="trsText--text text-body-2 text--lighten-1 mt-1">
              Board approval date
            </div>
          </v-col>
          <v-col v-if="type === CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES">
            <div class="text--lighten-1 font-weight-medium">
              {{ $currencyWithPrecision(internalValue.price_per_share) }}
            </div>
            <div class="trsText--text text-body-2 text--lighten-1 mt-1">
              Price per share
            </div>
          </v-col>
          <v-col v-if="isDraft">
            <div>
              <status-chip :is-valid="internalValue.is_valid" />
            </div>
          </v-col>
        </div>
      </v-col>
    </v-row>
    <v-row class="my-4">
      <v-col
        cols="12"
        class="info-container body-container"
      >
        <div class="text--lighten-1 font-weight-medium mb-2">
          Summary
        </div>
        <div class="wrapper">
          <div v-if="type !== CONSTS.NOTES_DOCUMENT_API_TYPE.OPTIONS">
            <span class="mr-5">
              {{ typeWiseData.title }} terms:
              <span class="text--lighten-1 text-body-3 font-weight-medium">
                {{ typeWiseData.termName }}
              </span>
            </span>
          </div>
          <div v-if="type === CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANTS || type === CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES">
            <span class="mr-5">
              Grant date:
              <span class="text--lighten-1 text-body-3 font-weight-medium">
                {{ $date(internalValue.granted_on) }}
              </span>
            </span>
          </div>
          <div v-if="type === CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES">
            <span class="mr-5">
              Maturity Date:
              <span class="text--lighten-1 text-body-3 font-weight-medium">
                {{ $date(internalValue.matures_on) }}
              </span>
            </span>
          </div>
          <div v-if="type !== CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES">
            <span class="mr-5">
              Board approval date:
              <span class="text--lighten-1 text-body-3 font-weight-medium">
                {{ $date(internalValue.board_approved_on || internalValue.shareTermBoardApprovedOn) }}
              </span>
            </span>
          </div>
          <div v-if="internalValue && internalValue.equity_incentive_plan_id">
            <span
              v-if="type === CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES || type === CONSTS.NOTES_DOCUMENT_API_TYPE.OPTIONS"
              class="mr-5"
            >
              Equity Incentive Plan:
              <span class="text--lighten-1 text-body-3 font-weight-medium">
                {{ getEIP() }}
              </span>
            </span>
          </div>
          <div>
            <span class="mr-5">
              Stock registration name:
              <span class="text--lighten-1 text-body-3 font-weight-medium">
                {{ internalValue.registration_name }}
              </span>
            </span>
          </div>
          <div v-if="internalValue.expires_on">
            <span
              v-if="type === CONSTS.NOTES_DOCUMENT_API_TYPE.OPTIONS"
              class="mr-5"
            >
              Expiration date:
              <span class="text--lighten-1 text-body-3 font-weight-medium">
                {{ $date(internalValue.expires_on) }}
              </span>
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="type === CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES"
      class="my-4"
    >
      <v-col class="info-container body-container">
        <div class="text--lighten-1 font-weight-medium mb-2">
          Terms
        </div>
        <div class="wrapper">
          <div v-if="type === CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES">
            <span class="mr-5">
              Valuation cap:
              <span class="text--lighten-1 text-body-3 font-weight-medium">
                {{ $currencyWithPrecision(internalValue.valuation_cap) }}
              </span>
            </span>
          </div>
          <div v-if="type === CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES">
            <span class="mr-5">
              Conversion discount:
              <span class="text--lighten-1 text-body-3 font-weight-medium">
                {{ getPercentValue(internalValue.conversion_discount) }}
              </span>
            </span>
          </div>
          <div v-if="internalValue.interest_terms">
            <span class="mr-5">
              Interest:
              <span class="text--lighten-1 text-body-3 font-weight-medium">
                {{ typeWiseData.interest }}
                {{ getPercentValue(internalValue.interest_terms.rate) }} {{ internalValue.interest_terms.type }} interest accrued {{ internalValue.interest_terms.period }}
              </span>
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="internalValue.vesting_schedule"
      class="my-4"
    >
      <v-col
        v-if="type === CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES || type === CONSTS.NOTES_DOCUMENT_API_TYPE.OPTIONS"
        class="info-container body-container"
      >
        <div class="text--lighten-1 font-weight-medium mb-2">
          Vesting Schedule
        </div>
        <div class="wrapper">
          <div>
            <span class="mr-5">
              Name:
              <span class="text--lighten-1 text-body-3 font-weight-medium">
                {{ internalValue.vesting_schedule.name }}
              </span>
            </span>
          </div>
          <div v-if="internalValue.vesting_schedule.custom.length === 0">
            <span class="mr-5">
              Schedule:
              <span class="text--lighten-1 text-body-3 font-weight-medium">
                {{ `${(100 - (internalValue.vesting_schedule.immediate_vest_percent * 100)).toFixed(2)}% vested ${internalValue.vesting_schedule.time_based.vesting_period} (${internalValue.vesting_schedule.time_based.vesting_term} ${vestingPeriod().text})` }}
              </span>
            </span>
          </div>
          <div v-if="internalValue.vesting_schedule.time_based && internalValue.vesting_schedule.time_based.cliff">
            <span class="mr-5">
              Cliff Vesting:
              <span class="text--lighten-1 text-body-3 font-weight-medium">
                {{ `${(internalValue.vesting_schedule.time_based.cliff.percent) * 100} % after ${internalValue.vesting_schedule.time_based.cliff.duration} ${vestingPeriod().text}` }}
              </span>
            </span>
          </div>
          <div>
            <span class="mr-5">
              Vesting start date:
              <span class="text--lighten-1 text-body-3 font-weight-medium">
                {{ $date(internalValue.vesting_started_on) }}
              </span>
            </span>
          </div>
          <div v-if="internalValue.vesting_schedule.custom.length>0">
            <div
              v-for="vest, index in internalValue.vesting_schedule.custom"
              :key="index"
            >
              Vest:
              <span
                class="text--lighten-1 text-body-3 font-weight-medium"
              >
                {{ getPercentValue(vest.percent) }} on {{ vest.vests_on }} for {{ vest.reason }}
              </span>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="my-4">
      <v-col class="info-container body-container">
        <div class="my-2">
          <div class="text--lighten-1 font-weight-medium">
            Documents
          </div>
          <view-documents
            :id="id"
            :type="type"
            :title="'Documents'"
            :documents-handler="getDocuments"
          />
        </div>
        <v-divider />
        <div class="my-2">
          <div class="text--lighten-1 font-weight-medium">
            Notes
          </div>
          <view-notes
            :id="id"
            :type="type"
            :notes-handler="getNotes"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="d-flex float-right">
          <div v-if="isDraft">
            <edit-modal
              v-feature="CONSTS.CAPTABLE_FEATURES.EDIT_SECURITY"
              :title="isDraft ? `Edit ${typeWiseData.title}s`: `Modify ${typeWiseData.title}s`"
              :details="internalValue"
              :holders-list="holdersList"
              :type="type"
              :terms-list="termsList"
              :eip-list="eipList"
              :financing-round-list="financingRoundList"
              :is-draft="isDraft"
              @reload="$emit('reload')"
            >
              <template v-slot:activator="{ on }">
                <a
                  v-on=" on "
                >
                  <common-trs-btn
                    type="secondary"
                    medium
                    class="mr-2"
                    text
                    @click="$emit('close')"
                  >
                    Edit Draft
                  </common-trs-btn>
                </a>
              </template>
            </edit-modal>
            <common-trs-btn
              v-feature="CONSTS.CAPTABLE_FEATURES.ISSUE_SECURITY"
              type="primary"
              medium
              class="white--text"
              text
              :disabled="!isSelectable && !internalValue.is_valid"
              @click="issueEntity"
            >
              Issue {{ type }}
            </common-trs-btn>
          </div>
          <div v-else>
            <common-trs-btn
              type="secondary"
              medium
              class="mr-2"
              text
              @click="$emit('close')"
            >
              Close
            </common-trs-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import StatusChip from '@/components/common/captable/components/StatusChip'
  import ViewDocuments from '@/components/common/captable/components/ViewDocuments'
  import ViewNotes from '@/components/common/captable/components/ViewNotes'
  import EditModal from './EditModal'
  export default {
    name: 'ViewModal',

    components: {
      StatusChip,
      ViewDocuments,
      EditModal,
      ViewNotes,
    },

    props: {
      details: {
        type: Object,
        required: true,
        default: () => {},
      },
      holdersList: {
        type: Array,
        required: true,
        default: () => [],
      },
      termsList: {
        type: Array,
      },
      eipList: {
        type: Array,
      },
      financingRoundList: {
        type: Array,
      },
      internalDialog: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
      },
      id: {
        type: String,
        default: '',
      },
      isSelectable: {
        type: Boolean,
        default: false,
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      getNotes: false,
      getDocuments: false,
      typeWiseData: {
        title: '',
        termName: '',
      },
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      isDialogActive: {
        get () {
          return this.internalDialog || false
        },
        set (val) {
          this.$emit('input', val)
        },
      },
      internalValue: {
        get () {
          return this.details || {}
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      isDialogActive: {
        handler (val) {
          if (val) {
            this.getNotes = true
            this.getDocuments = true
          } else {
            this.getNotes = false
            this.getDocuments = false
          }
        },
        immediate: true,
      },
    },

    mounted () {
      this.setTypeWiseData()
    },

    methods: {
      setTypeWiseData () {
        switch (this.type) {
          case this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES:
            this.typeWiseData.title = 'Share'
            this.typeWiseData.termName = this.internalValue.shareName
            break
          case this.CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANTS:
            this.typeWiseData.title = 'Warrant'
            this.typeWiseData.termName = this.internalValue.warrantName
            break
          case this.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES:
            this.typeWiseData.title = 'Convertible'
            this.typeWiseData.termName = this.internalValue.convertibleName
            this.typeWiseData.interest = this.getInterest()
            break
          case this.CONSTS.NOTES_DOCUMENT_API_TYPE.OPTIONS:
            this.typeWiseData.title = 'Option'
            break
          default:
            break
        }
      },
      getEIP () {
        let matchedEIP = {}
        matchedEIP = this.eipList.find(eip => this.internalValue.equity_incentive_plan_id === eip.id)
        return matchedEIP.name
      },
      vestingPeriod () {
        return this.CONSTS.VESTING_PERIOD_TYPE.find(o => o.value === this.internalValue.vesting_schedule.time_based.vesting_period)
      },
      getPercentValue (value) {
        if (!isNaN(value)) {
          return (value * 100).toFixed(2) + '%'
        } else {
          return 'NA'
        }
      },
      getInterest () {
        return this.termsList.find(term => term.id === this.internalDialog.convertible_terms_id)
      },
      getOptionActionType () {
        let type = ''
        switch (this.internalValue.type) {
          case 'iso':
            type = 'ISO'
            break
          case 'nso':
            type = 'NSO'
            break
          case 'iso_nso':
            type = 'ISO/NSO'
            break

          default:
            break
        }
        return type
      },
      close () {
        this.internalDialog = false
        this.$emit('close')
      },
      issueEntity () {
        this.$emit('issueHandler')
        this.$emit('close')
      },
    },
  }
</script>

<style scoped>
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  .info-container{
    border:1px solid #e5e5e5;
    border-radius: 4px;
    padding: 12px;
  }
  .header-container{
    background-color: rgba(190, 206, 234, 0.35);
  }
  .body-container{
    padding: 24px;
  }
</style>
