<template>
  <v-dialog
    v-model="internalDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :max-width="maxWidth"
    scrollable
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:activator="{ on }">
      <slot
        name="activator"
        v-bind="{ on }"
      />
    </template>
    <common-trs-modal-card>
      <common-trs-modal-card-title>
        <common-trs-modal-card-title-content>
          {{ title }}
        </common-trs-modal-card-title-content>

        <common-trs-btn
          aria-label="Close"
          type="secondary"
          class="rounded-0"
          color="primary"
          size="large"
          icon
          text
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </common-trs-btn>
      </common-trs-modal-card-title>
      <common-trs-modal-card-text>
        <transfer-share
          v-if="actionType == CONSTS.ISSUED_SECURITY_ACTIONS.TRANSFER"
          :share-details="shareDetails"
          :holders-list="holdersList"
          :is-modal-closed="isModalClosed"
          @close="close"
          @reload="$emit('reload')"
        />
        <vesting-schedule-summary
          v-if="actionType == CONSTS.ISSUED_SECURITY_ACTIONS.VESTING"
          :share-details="shareDetails"
          :is-vesting-available="isVestingAvailable"
          :holders-list="holdersList"
          :is-modal-closed="isModalClosed"
          @close="close"
          @reload="$emit('reload')"
        />
        <repurchase-share
          v-if="actionType == CONSTS.ISSUED_SECURITY_ACTIONS.REPURCHASE"
          :share-details="shareDetails"
          :holders-list="holdersList"
          :is-modal-closed="isModalClosed"
          @close="close"
          @reload="$emit('reload')"
        />
        <cancel-share
          v-if="actionType == CONSTS.ISSUED_SECURITY_ACTIONS.CANCEL"
          :share-details="shareDetails"
          :holders-list="holdersList"
          :is-modal-closed="isModalClosed"
          @close="close"
          @reload="$emit('reload')"
        />
        <modify-share
          v-if="actionType == CONSTS.ISSUED_SECURITY_ACTIONS.MODIFY"
          :share-details="shareDetails"
          :holders-list="holdersList"
          :internal-dialog="internalDialog"
          :share-terms-list="shareTermsList"
          :eip-list="eipList"
          :financing-round-list="financingRoundList"
          :is-draft="isDraft"
          @close="close"
          @reload="$emit('reload')"
        />
        <view-modal
          v-if="actionType == CONSTS.ISSUED_SECURITY_ACTIONS.VIEW"
          :id="shareDetails.issuedShareId"
          :details="shareDetails"
          :holders-list="holdersList"
          :internal-dialog="internalDialog"
          :terms-list="shareTermsList"
          :type="CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES"
          :eip-list="eipList"
          :financing-round-list="financingRoundList"
          :is-modal-closed="isModalClosed"
          :is-draft="isDraft"
          @issueHandler="$emit('issueHandler')"
          @close="close"
          @reload="$emit('reload')"
        />
      </common-trs-modal-card-text>
    </common-trs-modal-card>
  </v-dialog>
</template>
<script>
  import TransferShare from './TransferShare'
  import RepurchaseShare from './RepurchaseShare'
  import CancelShare from './CancelShare'
  import ModifyShare from './ModifyShare'
  import ViewModal from '../../ViewModal'
  import VestingScheduleSummary from './VestingScheduleSummary.vue'

  export default {
    name: 'SecuritiesActionModal',

    components: {
      TransferShare,
      RepurchaseShare,
      CancelShare,
      ModifyShare,
      ViewModal,
      VestingScheduleSummary,
    },

    props: {
      title: {
        type: String,
      },
      shareDetails: {
        type: Object,
        required: true,
        default: () => {},
      },
      holdersList: {
        type: Array,
        required: true,
        default: () => [],
      },
      actionType: {
        type: String,
        required: true,
      },
      shareTermsList: {
        type: Array,
        default: () => [],
      },
      eipList: {
        type: Array,
        default: () => [],
      },
      financingRoundList: {
        type: Array,
        default: () => [],
      },
      maxWidth: {
        type: Number,
        default: 700,
      },
      isVestingAvailable: {
        type: Boolean,
        default: false,
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      internalDialog: false,
      isModalClosed: false,
    }),

    watch: {
      internalDialog: {
        handler (newVal, oldVal) {
          if (oldVal && !newVal) {
            this.isModalClosed = true
            this.$emit('close')
          }
        },
        immediate: true,
      },
    },

    methods: {
      close () {
        this.internalDialog = false
        this.$emit('close')
      },
    },
  }
</script>
