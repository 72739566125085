var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",staticClass:"ml-auto",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('holders-selection-form',{attrs:{"title":'Select holder',"holders-list":_vm.holdersList},on:{"update":_vm.updateHolderId},model:{value:(_vm.holder),callback:function ($$v) {_vm.holder=$$v},expression:"holder"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Convertible term',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Convertible Term","placeholder":"Select convertible term","items":_vm.convertibleTermsList,"item-text":"name","item-value":"id","error-messages":errors,"hide-details":errors.length === 0},on:{"change":_vm.setPrefilledData},model:{value:(_vm.formData.convertible_terms_id),callback:function ($$v) {_vm.$set(_vm.formData, "convertible_terms_id", $$v)},expression:"formData.convertible_terms_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Financing round',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Financing Round","placeholder":"Select financing round","items":_vm.financingRoundList,"hide-details":errors.length === 0,"item-text":"name","item-value":"id","error-messages":errors},model:{value:(_vm.formData.convertibleFinancingRoundId),callback:function ($$v) {_vm.$set(_vm.formData, "convertibleFinancingRoundId", $$v)},expression:"formData.convertibleFinancingRoundId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Invested Capital',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Invested Capital","color":"primary","error-messages":errors,"hide-details":errors.length === 0,"options":{ locale: 'en-US', precision: 2 },"prepend-inner-icon":"mdi-currency-usd"},model:{value:(_vm.formData.amount_invested),callback:function ($$v) {_vm.$set(_vm.formData, "amount_invested", $$v)},expression:"formData.amount_invested"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":'Stock registration name',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Stock Registration Name","color":"primary","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.stockRegistrationName),callback:function ($$v) {_vm.stockRegistrationName=$$v},expression:"stockRegistrationName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":'Grant date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"input-templ":{label: 'Grant date', mandatory: true},"error-messages":errors,"hide-details":errors.length === 0,"clearable":""},model:{value:(_vm.grantDate),callback:function ($$v) {_vm.grantDate=$$v},expression:"grantDate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":'Maturation date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"input-templ":{label: 'Maturation date', mandatory: true},"error-messages":errors,"hide-details":errors.length === 0,"clearable":""},model:{value:(_vm.maturedDate),callback:function ($$v) {_vm.maturedDate=$$v},expression:"maturedDate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":'Board approval date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"input-templ":{label: 'Board approval date', mandatory: true},"error-messages":errors,"hide-details":errors.length === 0,"clearable":""},model:{value:(_vm.boardApprovedOn),callback:function ($$v) {_vm.boardApprovedOn=$$v},expression:"boardApprovedOn"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('notes-and-documents-form',{attrs:{"id":_vm.convertibleDetails.issuedConvertibleId,"type":_vm.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES,"internal-dialog":_vm.internalDialog,"mode":'edit',"is-submit":_vm.isSubmit},on:{"navToList":_vm.navToList}})],1)],1),_c('v-row',[_c('v-col',[_c('v-divider'),_c('div',{staticClass:"mt-2 d-flex justify-space-between"},[_c('common-trs-btn',{staticClass:"mr-2",attrs:{"type":"secondary","medium":"","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('common-trs-btn',{staticClass:"white--text",attrs:{"type":"primary","medium":"","text":"","disabled":!valid,"loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }