<template>
  <span>
    <v-chip
      v-if="isValid"
      color="success"
      outlined
    >
      Ready to be issued
    </v-chip>
    <v-chip
      v-else
      color="error"
      outlined
    >
      Missing required information
    </v-chip>
  </span>
</template>
<script>
  export default {
    name: 'StatusChip',
    props: {
      isValid: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
<style scoped>
::v-deep .v-chip.v-size--default {
    height:20px !important;
    }
</style>
