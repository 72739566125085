var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",staticClass:"ml-auto",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('securities-summary-view',{attrs:{"security-type":_vm.securityType,"security-details":_vm.securityDetails,"holder-details":_vm.holderDetails}}),_c('v-row',{staticClass:"mt-8"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":{ required: true },"name":'Transfer date'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"input-templ":{label: 'Transfer date', mandatory: true},"error-messages":errors,"clearable":""},model:{value:(_vm.transferredOn),callback:function ($$v) {_vm.transferredOn=$$v},expression:"transferredOn"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Transfer basis',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Transfer Basis","placeholder":"Select transfer basis","items":_vm.CONSTS.ISSUED_SHARE_TRANSFER_BASIS_LIST,"item-text":"text","item-value":"value","error-messages":errors},model:{value:(_vm.formData.transfer_basis),callback:function ($$v) {_vm.$set(_vm.formData, "transfer_basis", $$v)},expression:"formData.transfer_basis"}})]}}],null,true)})],1)],1),_c('holders-selection-form',{attrs:{"title":'Transfer to',"holders-list":_vm.holdersList},on:{"update":_vm.updateHolderId},model:{value:(_vm.holder),callback:function ($$v) {_vm.holder=$$v},expression:"holder"}}),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":'No. of shares',"rules":_vm.numOfSharesValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"No. of Shares","color":"primary","error-messages":errors,"hint":("You have " + (_vm.shareDetails.number) + " shares available to repurchase."),"persistent-hint":true},on:{"input":_vm.calculate},model:{value:(_vm.formData.number),callback:function ($$v) {_vm.$set(_vm.formData, "number", $$v)},expression:"formData.number"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-center mt-3",attrs:{"cols":"1"}},[_vm._v(" X ")]),_c('v-col',[_c('validation-provider',{attrs:{"name":'Price per Share',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Price per share","color":"primary","error-messages":errors,"options":{ locale: 'en-US', precision: 2 },"prepend-inner-icon":"mdi-currency-usd"},on:{"input":_vm.calculate},model:{value:(_vm.formData.price_per_share),callback:function ($$v) {_vm.$set(_vm.formData, "price_per_share", $$v)},expression:"formData.price_per_share"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[(!_vm.calculatedAmount)?_c('span',[_vm._v("= NA")]):_c('span',[_vm._v("= "+_vm._s(_vm.$currencyWithPrecision(_vm.calculatedAmount))+" ")]),_c('div',{staticClass:"trsText--text text-body-2 text--lighten-1 mt-1"},[_vm._v(" Transfer cost ")])])],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":'Stock registration name',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Stock registration name","color":"primary","error-messages":errors},model:{value:(_vm.formData.registration_name),callback:function ($$v) {_vm.$set(_vm.formData, "registration_name", $$v)},expression:"formData.registration_name"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-divider'),_c('div',{staticClass:"mt-2 d-flex justify-space-between"},[_c('common-trs-btn',{staticClass:"mr-2",attrs:{"type":"secondary","medium":"","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('common-trs-btn',{staticClass:"white--text",attrs:{"type":"primary","medium":"","text":"","disabled":!valid,"loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }