<template>
  <div
    style="width: 50px;"
    class="text-center"
  >
    <v-menu
      ref="menu"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <common-trs-btn
          light
          text
          type="secondary"
          class="mx-1 rounded-0"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="black--text">
            mdi-dots-vertical
          </v-icon>
        </common-trs-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            <securities-action-modal
              :title="isDraft ? 'View Draft': 'Warrants Summary'"
              :warrant-details="warrantDetails"
              :holders-list="holdersList"
              :warrant-terms-list="warrantTermsList"
              :financing-round-list="financingRoundList"
              :max-width="1280"
              :is-draft="isDraft"
              :action-type="CONSTS.ISSUED_SECURITY_ACTIONS.VIEW"
              :eip-list="eipList"
              @close="closeMenu"
              @issueHandler="$emit('issue')"
              @reload="$emit('reload')"
            >
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                >
                  {{ isDraft ? 'View': 'View Summary' }}
                </a>
              </template>
            </securities-action-modal>
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-feature="CONSTS.CAPTABLE_FEATURES.EDIT_SECURITY">
          <v-list-item-title>
            <securities-action-modal
              :title="isDraft ? 'Edit Warrants':'Modify Warrants'"
              :warrant-details="warrantDetails"
              :holders-list="holdersList"
              :action-type="CONSTS.ISSUED_SECURITY_ACTIONS.MODIFY"
              :warrant-terms-list="warrantTermsList"
              :eip-list="eipList"
              :financing-round-list="financingRoundList"
              :is-draft="isDraft"
              @reload="$emit('reload')"
              @close="closeMenu"
            >
              <template v-slot:activator="{ on }">
                <a
                  v-on="{ ...on }"
                >
                  {{ isDraft ? 'Edit': 'Modify' }}
                </a>
              </template>
            </securities-action-modal>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="isDraft && warrantDetails.is_valid"
          v-feature="CONSTS.CAPTABLE_FEATURES.ISSUE_SECURITY"
        >
          <v-list-item-title>
            <span
              class="primary--text cursor-pointer"
              @click="$emit('issue')"
            >
              Issue
            </span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="!isDraft"
          v-feature="CONSTS.CAPTABLE_FEATURES.RAISE_SECURITY_ACTION_REQUEST"
        >
          <v-list-item-title>
            <securities-action-modal
              :title="'Exercise Warrants'"
              :holders-list="holdersList"
              :warrant-details="warrantDetails"
              :action-type="CONSTS.ISSUED_SECURITY_ACTIONS.EXERCISE"
              @reload="$emit('reload')"
              @close="closeMenu"
            >
              <template v-slot:activator="{ on }">
                <a
                  v-on="{ ...on }"
                >
                  Exercise
                </a>
              </template>
            </securities-action-modal>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="!isDraft"
          v-feature="CONSTS.CAPTABLE_FEATURES.RAISE_SECURITY_ACTION_REQUEST"
        >
          <v-list-item-title>
            <securities-action-modal
              :title="'Cancel Warrants'"
              :holders-list="holdersList"
              :warrant-details="warrantDetails"
              :action-type="CONSTS.ISSUED_SECURITY_ACTIONS.CANCEL"
              @reload="$emit('reload')"
              @close="closeMenu"
            >
              <template v-slot:activator="{ on }">
                <a
                  v-on="{ ...on }"
                >
                  Cancel
                </a>
              </template>
            </securities-action-modal>
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-feature="CONSTS.CAPTABLE_FEATURES.DELETE_SECURITY">
          <v-list-item-title>
            <common-app-confirm
              title="Delete Warrant"
              message="Are you sure you want to delete this warrant?"
              yes-button="Yes"
              no-button="Cancel"
              @yes="deleteIssuedShare"
              @close="closeMenu"
            >
              <template v-slot:activator="{ on }">
                <a
                  v-on="{ ...on }"
                >
                  Delete
                </a>
              </template>
            </common-app-confirm>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  import SecuritiesActionModal from './SecuritiesActionModal'
  export default {
    name: 'IssuedWarrantsActionse',

    components: {
      SecuritiesActionModal,
    },

    props: {
      warrantDetails: {
        type: Object,
        required: true,
        default: () => {},
      },
      holdersList: {
        type: Array,
        required: true,
        default: () => [],
      },
      warrantTermsList: {
        type: Array,
        required: true,
      },
      eipList: {
        type: Array,
        required: true,
      },
      financingRoundList: {
        type: Array,
        required: true,
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      deleteIssuedShare () {
        this.$emit('delete')
      },
      closeMenu () {
        this.$refs.menu.isActive = false
      },
    },
  }
</script>
<style lang="scss" scoped>
 .v-list-item {
  min-height: 40px!important;
    &:hover {
      color: var(--v-primary-base) !important;
      background-color: rgba(0, 159, 70, 0.1)!important;
    }
 }
</style>
