<template>
  <div>
    <validation-observer
      ref="observer"
      class="ml-auto"
    >
      <!-- Summary View Section -->
      <securities-summary-view
        :security-type="securityType"
        :security-details="securityDetails"
        :holder-details="holderDetails"
        :is-vesting-available="isVestingAvailable"
      />
      <common-trs-card class="pa-2">
        <div class="d-flex justify-space-between my-5">
          <div>
            <span>
              Vested as of today:
            </span>
            <span class="font-weight-bold">
              {{ $number(vestedAsOfToday()) }} ({{ vestedAsOfPercent }}%)
            </span>
          </div>

          <span>
            <v-tooltip
              left
            >
              <template v-slot:activator="{on: tooltip }">
                <common-trs-btn
                  type="tertiary"
                  class="mx-1 rounded-0"
                  icon
                  color="primary"
                  v-on="{...tooltip }"
                  @click="toggleChart"
                >
                  <v-icon
                    small
                    dark
                  >
                    {{ isChart ? 'mdi-trending-up ':'mdi-table' }}
                  </v-icon>
                </common-trs-btn>
              </template>
              <span>{{ isChart ? 'Graph View':'Table view' }}</span>
            </v-tooltip>
          </span>
        </div>

        <!-- Table Section -->
        <common-trs-data-table
          v-if="isChart"
          :headers="headers"
          :items="tableData"
          :loading="loading"
        >
          <template v-slot:item.serial_no="{item }">
            {{ item.serial_no }}
          </template>
          <template v-slot:item.vested_on="{ item}">
            {{ item.vested_on }}
          </template>
          <template v-slot:item.number="{item }">
            {{ item.number === 0 ? 'NA': $number(item.number) }}
          </template>
          <template v-slot:item.cumulative="{item }">
            {{ item.cumulative === 0 ? 'NA': $number(item.cumulative) }}
          </template>
          <template
            v-slot:item.vest_type="{item }"
          >
            <span class="text-capitalize">
              {{ item.vest_type }}

            </span>
          </template>
          <template v-slot:item.description="{item }">
            {{ item.description }}
          </template>
        </common-trs-data-table>
        <!-- Chart Section -->
        <plotly
          v-else
          :data="chartData"
          :layout="chartLayout"
          :static-plot="true"
        />
      </common-trs-card>

      <!-- Form Buttons Section -->
      <v-row>
        <v-col>
          <v-divider />
          <div class="mt-2 d-flex justify-space-between">
            <common-trs-btn
              type="secondary"
              medium
              class="mr-2"
              text
              @click="$emit('close')"
            >
              Cancel
            </common-trs-btn>
          </div>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>
<script>
  import SecuritiesSummaryView from '@/components/common/captable/components/SecuritiesSummaryView'
  import { Plotly } from 'vue-plotly'
  import moment from 'moment'

  export default {
    name: 'TransferShare',

    components: {
      SecuritiesSummaryView, Plotly,
    },

    props: {
      optionDetails: {
        type: Object,
        required: true,
        default: () => {},
      },
      holdersList: {
        type: Array,
        required: true,
        default: () => [],
      },
      isModalClosed: {
        type: Boolean,
        default: false,
      },
      isVestingAvailable: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      isChart: false,
      loading: false,
      transferredOn: '',
      headers: [
        { text: 'Period', value: 'serial_no', sortable: true },
        { text: 'Date', value: 'vested_on', sortable: true },
        { text: 'Vested', value: 'number', sortable: true },
        { text: 'Cumulative', value: 'cumulative', sortable: true },
        { text: 'Vest Type', value: 'vest_type', sortable: true },
        { text: 'Description', value: 'description', sortable: true },
      ],
    }),

    computed: {
      securityType () {
        return this.optionDetails.type || this.CONSTS.SHARE_TERM_TYPES.COMMON
      },
      securityDetails () {
        return {
          name: this.optionDetails.shareName || '',
          numberOfSharesGranted: this.optionDetails.number_granted || 0,
          pricePerShare: this.optionDetails.exercise_price || 0,
          grantDate: this.optionDetails.granted_on || '',
          investedCapital: this.optionDetails.type || this.CONSTS.SHARE_TERM_TYPES.OPTION,
        }
      },
      holderDetails () {
        return {
          name: this.optionDetails.holderName || '',
          email: this.optionDetails.email || '',
          id: this.optionDetails.holderId || '',
        }
      },

      vestingScheduleData () {
        return this.optionDetails.vesting_schedule && this.optionDetails.vesting_schedule !== null ? this.optionDetails.vesting_schedule : {}
      },
      vestingActionData () {
        return this.optionDetails.vest_actions && this.optionDetails.vest_actions !== null ? this.optionDetails.vest_actions : {}
      },
      chartLayout () {
        const layout = {
          showlegend: true,
          legend: {
            x: 0,
            y: -0.2,
            orientation: 'h',
          },
          autosize: true,
          width: 1150,
          xaxis: {
            showgrid: true,
            zeroline: true,
            showline: true,
            linecolor: '#4B4C49',
            rangemode: 'tozero',
            tickformat: '%b-%d %Y',
            showticklabels: this.showticklabels,
          },
          yaxis: {
            showgrid: true,
            zeroline: true,
            showline: true,
            rangemode: 'tozero',
            linecolor: '#D3D3D4',
          },
        }
        return layout
      },
      itemsWithSno () {
        return this.vestingActionData.map((d, index) => ({ ...d, serial_no: index + 1 }))
      },
      chartData () {
        try {
          const data1 = {
            x: this.vestingActionData.map(ele => ele.vested_on) || [null],
            y: this.findCumulativeSum(this.vestingActionData) || [null],
            line_shape: 'hv',
            mode: 'lines',
            name: 'Scheduled future vesting',
            line: { shape: 'hv', dash: 'dot', color: 'rgb(55, 128, 191)' },
            connectgaps: true,
          }
          const data2 = {
            x: this.findVestedData(this.tableData).map(ele => ele.vested_on).sort() || [null],
            y: this.findVestedData(this.tableData).map(ele => ele.cumulative).sort((a, b) => a - b) || [null],
            line_shape: 'hv',
            mode: 'lines',
            name: 'Total shares vested',
            line: { shape: 'hv', dash: 'solid', width: 3, color: 'rgb(55, 128, 191)' },
            connectgaps: true,
          }
          const data = [data2, data1]
          return data
        } catch (err) {
          const data = [
            {
              x: [null],
              y: [null],
              name: 'Total shares vested',
              line: { dash: 'solid', color: 'rgb(55, 128, 191)' },
            },
            {
              x: [null],
              y: [null],
              name: 'Scheduled future vesting',
              line: { dash: 'dot', color: 'rgb(55, 128, 191)' },
            },
          ]
          return data
        }
      },
      tableData () {
        let c = 0
        const arr = []
        this.itemsWithSno.map((x) => {
          arr.push({
            ...x, cumulative: c += x.number,
          })
        })
        return arr
      },
      vestedAsOf () {
        return this.vestingActionData.filter((x) => x.vested_on <= moment().format('YYYY-MM-DD'))
      },
      vestedAsOfPercent () {
        return (((this.vestedAsOfToday() / this.optionDetails.number_granted) * 100) || 0).toFixed(2)
      },
      showticklabels () {
        return (this.chartData[0].x.length > 1 || this.chartData[0].length > 1) || false
      },
    },

    methods: {
      toggleChart () {
        this.isChart = !this.isChart
      },
      findVestedData (arr) {
        let combined
        arr.filter((x, i) => {
          if (x.vested_on <= moment().format('YYYY-MM-DD')) {
            const next = []
            next.push(arr[i + 1])
            combined = [...next, x]
          }
        })
        return combined
      },

      findCumulativeSum (arr) {
        const vested = arr.reduce((acc, val) => {
          let { sum, res } = acc
          sum += val.number
          res.push(sum)
          return { sum, res }
        }, {
          sum: 0,
          res: [],
        })
        return vested.res
      },
      vestedAsOfToday () {
        const vested = this.vestedAsOf.reduce((acc, val) => {
          let { sum, res } = acc
          sum += val.number
          res.push(sum)
          return { sum, res }
        }, {
          sum: 0,
          res: [],
        })
        return vested.sum
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .legend .traces .legendtoggle {
   display: none;
}
</style>
