<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ valid }"
      class="ml-auto"
    >
      <!-- Summary View Section -->
      <securities-summary-view
        :security-type="securityType"
        :security-details="securityDetails"
        :holder-details="holderDetails"
      />

      <!-- Exercise Details Section -->
      <v-row class="mt-5">
        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Amount'"
            :rules="numOfConvertiblesValidation"
          >
            <common-input-currency-input
              v-model="formData.amount"
              label="Amount"
              color="primary"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              :options="{ locale: 'en-US', precision: 2 }"
              prepend-inner-icon="mdi-currency-usd"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Cancelation date'"
            :rules="{ required: true }"
          >
            <input-template-date
              v-model="cancellationDate"
              :mask="'##/##/####'"
              :input-templ="{label: 'Cancelation date', mandatory: true}"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              clearable
            />
          </validation-provider>
        </v-col>
      </v-row>

      <!-- Form Buttons Section -->
      <v-row>
        <v-col>
          <v-divider />
          <div class="mt-2 d-flex justify-space-between">
            <common-trs-btn
              type="secondary"
              medium
              class="mr-2"
              text
              @click="$emit('close')"
            >
              Cancel
            </common-trs-btn>
            <common-trs-btn
              type="primary"
              medium
              class="white--text"
              text
              :disabled="!valid"
              :loading="loading"
              @click="save"
            >
              Save
            </common-trs-btn>
          </div>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'
  import SecuritiesSummaryView from '@/components/common/captable/components/SecuritiesSummaryView'

  import * as captableAdminService from '@/services/captable-admin-service'

  export default {
    name: 'CancelConvertible',

    components: {
      SecuritiesSummaryView,
      InputTemplateDate,
    },

    props: {
      convertibleDetails: {
        type: Object,
        required: true,
        default: () => {},
      },
      isModalClosed: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      formData: {},
      loading: false,
      numOfConvertiblesValidation: {},
      securityType: {},
      securityDetails: {},
      holderDetails: {},
      cancellationDate: '',
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      convertibleDetails: {
        handler (val) {
          if (val) {
            this.numOfConvertiblesValidation = {
              required: true,
              max_value: val.amount,
              min_value: 0,
            }
            this.securityType = this.convertibleDetails.convertible_type ||
              this.CONSTS.SHARE_TERM_TYPES.WARRANT
            this.securityDetails = {
              name: this.convertibleDetails.convertibleName || '',
              investedCapital: this.convertibleDetails.amount || 0,
            }
            this.holderDetails = {
              name: this.convertibleDetails.holderName || '',
              email: this.convertibleDetails.email || '',
              id: this.convertibleDetails.holderId || '',
            }
            this.cancellationDate = val.canceled_on ? moment(val.canceled_on).format('MM/DD/YYYY') : ''
          }
        },
        immediate: true,
      },
      cancellationDate: {
        handler (val) {
          if (val) {
            this.formData.canceled_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      isModalClosed: {
        handler (val) {
          if (val) {
            this.formData = {}
            this.$refs.observer.reset()
            val = false
          }
        },
        immediate: true,
      },
    },

    methods: {
      async save () {
        this.loading = true
        if (this.formData.canceled_on > this.convertibleDetails.issued_on) {
          try {
            const details = {
              payload: this.formData,
              certificatePayload: {
                captableId: this.captableId,
                securityId: this.convertibleDetails.issuedConvertibleId,
                termDocType: this.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLE_TERMS,
                securityDocType: this.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES,
                termID: this.convertibleDetails.convertible_terms_id,
                registrationName: this.convertibleDetails.registration_name,
                amount: this.formData.amount,
                remainingNumber: this.convertibleDetails.number - this.formData.number,
              },
            }
            await captableAdminService.createServiceRequest(this.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES, this.CONSTS.ISSUED_SECURITY_ACTIONS.CANCEL, this.captableId, this.convertibleDetails.issuedConvertibleId, details)
            this.loading = false
            this.$refs.observer.reset()
            this.$emit('close')
            this.$emit('reload')
            this.formData = {}
            this.$store.dispatch('app/message', { text: captableAdminService.getServiceRequestSuccessMessage() })
          } catch (err) {
            this.loading = false
            this.$store.dispatch('app/handleError', {
              err,
              message: 'Sorry! Unable to cancel convertible. Please try again later.',
            })
          }
        } else {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            message: 'Sorry! Please select cancelation date greater than issue date.',
          })
        }
      },
    },
  }
</script>
