<template>
  <v-dialog
    v-model="internalDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :max-width="maxWidth"
    scrollable
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:activator="{ on }">
      <slot
        name="activator"
        v-bind="{ on }"
      />
    </template>
    <common-trs-modal-card>
      <common-trs-modal-card-title>
        <common-trs-modal-card-title-content>
          {{ title }}
        </common-trs-modal-card-title-content>

        <common-trs-btn
          aria-label="Close"
          type="secondary"
          class="rounded-0"
          color="primary"
          size="large"
          icon
          text
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </common-trs-btn>
      </common-trs-modal-card-title>
      <common-trs-modal-card-text>
        <modify-option
          v-if="actionType == CONSTS.ISSUED_SECURITY_ACTIONS.MODIFY"
          :internal-dialog="internalDialog"
          :option-details="optionDetails"
          :holders-list="holdersList"
          :share-terms-list="shareTermsList"
          :eip-list="eipList"
          :financing-round-list="financingRoundList"
          :is-draft="isDraft"
          @close="close"
          @reload="$emit('reload')"
        />
        <vesting-schedule-summary
          v-if="actionType == CONSTS.ISSUED_SECURITY_ACTIONS.VESTING"
          :option-details="optionDetails"
          :is-vesting-available="isVestingAvailable"
          :holders-list="holdersList"
          :is-modal-closed="isModalClosed"
          @close="close"
          @reload="$emit('reload')"
        />
        <exercise-option
          v-if="actionType == CONSTS.ISSUED_SECURITY_ACTIONS.EXERCISE"
          :option-details="optionDetails"
          :is-modal-closed="isModalClosed"
          @close="close"
          @reload="$emit('reload')"
        />
        <terminate-option
          v-if="actionType == CONSTS.ISSUED_SECURITY_ACTIONS.TERMINATE"
          :option-details="optionDetails"
          :is-modal-closed="isModalClosed"
          @close="close"
          @reload="$emit('reload')"
        />
        <cancel-option
          v-if="actionType == CONSTS.ISSUED_SECURITY_ACTIONS.CANCEL"
          :option-details="optionDetails"
          :holders-list="holdersList"
          :is-modal-closed="isModalClosed"
          @close="close"
          @reload="$emit('reload')"
        />
        <view-modal
          v-if="actionType == CONSTS.ISSUED_SECURITY_ACTIONS.VIEW"
          :id="optionDetails.issuedOptionId"
          :details="optionDetails"
          :holders-list="holdersList"
          :internal-dialog="internalDialog"
          :terms-list="shareTermsList"
          :eip-list="eipList"
          :type="CONSTS.NOTES_DOCUMENT_API_TYPE.OPTIONS"
          :is-modal-closed="isModalClosed"
          :is-draft="isDraft"
          @close="close"
          @issueHandler="$emit('issueHandler')"
          @reload="$emit('reload')"
        />
      </common-trs-modal-card-text>
    </common-trs-modal-card>
  </v-dialog>
</template>
<script>
  import ModifyOption from './ModifyOption'
  import ExerciseOption from './ExerciseOption'
  import TerminateOption from './TerminateOption.vue'
  import CancelOption from './CancelOption.vue'
  import VestingScheduleSummary from './VestingScheduleSummary'
  import ViewModal from '../../ViewModal'
  export default {
    name: 'SecuritiesActionModal',

    components: {
      ModifyOption,
      ExerciseOption,
      TerminateOption,
      CancelOption,
      VestingScheduleSummary,
      ViewModal,
    },

    props: {
      title: {
        type: String,
      },
      optionDetails: {
        type: Object,
        required: true,
        default: () => {},
      },
      holdersList: {
        type: Array,
        required: true,
        default: () => [],
      },
      actionType: {
        type: String,
        required: true,
        default: '',
      },
      shareTermsList: {
        type: Array,
        default: () => [],
      },
      eipList: {
        type: Array,
        default: () => [],
      },
      financingRoundList: {
        type: Array,
        default: () => [],
      },
      isVestingAvailable: {
        type: Boolean,
        default: false,
      },
      maxWidth: {
        type: Number,
        default: 700,
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      internalDialog: false,
      isModalClosed: false,
    }),

    watch: {
      internalDialog: {
        handler (newVal, oldVal) {
          if (oldVal && !newVal) {
            this.isModalClosed = true
            this.$emit('close')
          }
        },
        immediate: true,
      },
    },

    methods: {
      close () {
        this.internalDialog = false
        this.$emit('close')
      },
    },
  }
</script>
