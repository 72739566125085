<template>
  <div>
    <common-trs-data-table
      v-model="selected"
      :headers="headers"
      :items="filteredData"
      item-key="issuedShareId"
      :loading="loading"
      :show-select="isIssueAllowed && !isSelectable"
      hide-default-footer
      class="share-table"
      @toggle-select-all="selectAllToggle"
    >
      <!-- headers -->
      <template v-slot:[`header.shareName`]="{ header }">
        <span class="trsText--text text-body-2 font-weight-bold"> {{ header.text }}</span>
      </template>
      <template v-slot:[`header.holderName`]="{ header }">
        <span>
          <div class="trsText--text text-body-2 font-weight-bold"> {{ header.text }}</div>
          <span class="trsText--text text-body-2 mt-1">
            {{ header.text2 }}
          </span>
        </span>
      </template>
      <template v-slot:[`header.number`]="{ header }">
        <span class="trsText--text text-body-2 font-weight-bold"> {{ header.text }}</span>
      </template>
      <template v-slot:[`header.price_per_share`]="{ header }">
        <span>
          <div class="trsText--text text-body-2 font-weight-bold"> {{ header.text }}</div>
          <span class="trsText--text text-body-2 mt-1">
            {{ header.text2 }}
          </span>
        </span>
      </template>
      <template v-slot:[`header.granted_on`]="{ header }">
        <span>
          <div class="trsText--text text-body-2 font-weight-bold"> {{ header.text }}</div>
          <span class="trsText--text text-body-2 mt-1">
            {{ header.text2 }}
          </span>
        </span>
      </template>
      <template v-slot:[`header.action`]="{ header }">
        <span
          class="trsText--text text-body-2 font-weight-bold"
        >
          {{ header.text }}
        </span>
      </template>
      <template
        v-slot:[`header.status`]="{ header }"
      >
        <span
          class="trsText--text text-body-2 font-weight-bold"
        > {{ header.text }}</span>
      </template>
      <!-- table body -->
      <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected && item.is_valid"
          :readonly="!item.is_valid"
          :disabled="!item.is_valid"
          :ripple="false"
          @input="select($event)"
        />
      </template>
      <template v-slot:[`item.shareName`]="{item}">
        <v-row class="trs--text text-body-1 no-gutters">
          <v-col
            cols="4"
            class="d-flex align-center"
          >
            <share-term-badge
              class="mx-1"
              :type="item.type"
              :show-single="true"
            />
          </v-col>
          <v-col>
            {{ item.shareName }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.holderName`]="{item }">
        <span>
          <span class="trs--text text-body-1">
            {{ item.holderName }}
          </span>
          <div class="trsText--text text-body-2 mt-1">
            {{ item.registration_name }}
          </div>
        </span>
      </template>
      <template v-slot:[`item.number`]="{ item}">
        <span class="trs--text text-body-1">
          {{ $number(item.number) }}
        </span>
      </template>
      <template v-slot:[`item.price_per_share`]="{ item}">
        <span>
          <span class="trs--text text-body-1">
            {{ $currencyWithPrecision(item.price_per_share) }}
          </span>
          <div class="trsText--text text-body-2 mt-1">
            {{ $currencyWithPrecision(item.price_per_share*item.number) }}
          </div>
        </span>
      </template>
      <template v-slot:[`item.granted_on`]="{ item}">
        <span>
          <span class="trs--text text-body-1">
            {{ $date(item.granted_on ) }}
          </span>
          <div class="trsText--text text-body-2 mt-1">
            {{ $date(item.board_approved_on ) }}
          </div>
        </span>
      </template>
      <template
        v-slot:[`item.status`]="{item}"
      >
        <status-chip :is-valid="item.is_valid" />
      </template>

      <template v-slot:[`item.action`]="{item}">
        <shares-actions
          :share-details="item"
          :holders-list="investors"
          :share-terms-list="shareTerms"
          :eip-list="eips"
          :financing-round-list="financingRounds"
          :is-selectable="isSelectable"
          @delete="$emit('delete',item)"
          @reload="$emit('reload')"
          @issue="$emit('issue',item)"
        />
      </template>
    </common-trs-data-table>
    <v-divider />
    <common-trs-data-table-list-pager
      v-model="pager"
      class="ml-2"
      :length="pages"
    />
  </div>
</template>
<script>
  import SharesActions from './../actions/shares/SharesActions'
  import ShareTermBadge from '@/components/common/captable/components/ShareTermBadge'
  import StatusChip from '@/components/common/captable/components/StatusChip'
  import { getFeaturePermission } from '@/services/utils'

  const TABLE_HEADERS = [
    { text: 'Share Terms', value: 'shareName', sortable: true, width: '200px' },
    { text: 'Holder Name', text2: '& Registration Name', value: 'holderName', sortable: true },
    { text: 'No. Of Shares', value: 'number', sortable: true },
    { text: 'Price Per Share', text2: ' & Invested Capital', value: 'price_per_share', sortable: true },
    { text: 'Grant Date ', text2: '& Board Approval Date', value: 'granted_on', sortable: true },
    { text: 'Status', value: 'status', sortable: true },
    { text: 'Actions', value: 'action', sortable: false },
  ]
  export default {
    name: 'SharesDataTable',

    components: {
      SharesActions,
      ShareTermBadge,
      StatusChip,
    },

    props: {
      hideFields: {
        validate: () => true,
        default: () => [],
      },
      allShares: {
        type: Array,
        required: true,
      },
      shareTerms: {
        type: Array,
        required: true,
      },
      financingRounds: {
        type: Array,
        required: true,
      },
      eips: {
        type: Array,
        required: true,
      },
      investors: {
        type: Array,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      search: {
        type: String,
        default: '',
      },
      isSelectable: {
        type: Boolean,
        default: false,
      },
      filter: {
        type: Object,
        default: () => {},
      },
    },

    data: () => ({
      items: [],
      headers: [],
      pager: {
        itemsPerPage: 5,
        page: 1,
        total: 0,
      },
      updatedItems: [],
      disabledCount: 0,
      isIssueAllowed: false,
    }),

    computed: {
      pages () {
        return Math.ceil(this.updatedItems.length / this.pager.itemsPerPage) || 1
      },

      selected: {
        get () {
          return []
        },
        set (val) {
          this.$emit('sharesSelected', val)
        },
      },

      filteredData () {
        const data = JSON.parse(JSON.stringify(this.updatedItems))
        const startFrom = (this.pager.page * this.pager.itemsPerPage) - this.pager.itemsPerPage
        return data.splice(startFrom, this.pager.itemsPerPage)
      },

      currentRoles () {
        return this.$store.getters['auth/roles']
      },
    },

    watch: {
      currentRoles: {
        handler (val) {
          if (val) {
            this.isIssueAllowed = getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.ISSUE_SECURITY, val)
          }
        },
        immediate: true,
      },
      hideFields: {
        handler (val) {
          this.headers = TABLE_HEADERS.filter(header => val.indexOf(header.value) === -1)
        },
        immediate: true,
      },
      pager: {
        handler (val) {
          this.pager.total = this.pages
        },
        deep: true,
        immediate: true,
      },
      pages: {
        handler (val) {
          if (val) {
            this.pager.total = val
          }
        },
        deep: true,
        immediate: true,
      },
      allShares: {
        handler () {
          this.getUpdatedSharesData()
        },
        immediate: true,
      },
      filter: {
        handler () {
          this.getUpdatedSharesList()
        },
        immediate: true,
        deep: true,
      },
      filteredData: {
        handler (val) {
          val.map(item => {
            if (!item.is_valid) this.disabledCount += 1
          })
        },
        immediate: true,
      },
      shareTerms: {
        handler () {
          this.getUpdatedSharesData()
        },
        immediate: true,
      },
      search: {
        handler (val) {
          if (val) {
            const orgItems = JSON.parse(JSON.stringify(this.items))
            this.updatedItems = orgItems.filter((item) => {
              if (item.holderName.toLowerCase().indexOf(val.toLowerCase()) > -1) {
                return item
              }
            })
          } else {
            this.updatedItems = JSON.parse(JSON.stringify(this.items))
          }
        },
        immediate: true,
      },
    },

    created () {
      this.pager.total = this.items.length
    },

    methods: {
      getUpdatedSharesData () {
        if (this.allShares && this.allShares.length > 0) {
          const issuerDataWithShareTerm = this.allShares.map(t1 => ({ issuedShareTermBoardApprovedOn: t1.board_approved_on, issuedShareId: t1.id, issuedShareFinancingRoundId: t1.financing_round_id, pricePerShare: t1.price_per_share, ...t1, ...this.shareTerms.find(t2 => { return t2.id === t1.share_terms_id }) }))
          const shareNameChange = issuerDataWithShareTerm.map(({ id: shareTermId, name: shareName, board_approved_on: shareTermBoardApprovedOn, ...rest }) => ({ shareName, shareTermId, shareTermBoardApprovedOn, ...rest }))
          const issuedSharesDataWithHolderAndShareTerm = shareNameChange.map(t1 => ({ ...t1, ...this.investors.find(t2 => t2.id === t1.holder_id) }))
          const holderNameChange = issuedSharesDataWithHolderAndShareTerm.map(({ name: holderName, id: holdersId, ...rest }) => ({ holderName, holdersId, ...rest }))
          holderNameChange.forEach((share) => {
            share.board_approved_on = share.issuedShareTermBoardApprovedOn
            share.financing_round_id = share.issuedShareFinancingRoundId
            share.price_per_share = share.pricePerShare
            delete share.issuedShareFinancingRoundId
            delete share.issuedShareTermBoardApprovedOn
            delete share.pricePerShare
          })
          this.items = [...holderNameChange]
        } else {
          this.items = []
        }
        this.getUpdatedSharesList()
        this.$emit('stopLoading')
      },
      getUpdatedSharesList () {
        const orgItems = JSON.parse(JSON.stringify(this.items))
        let isShareFilterSelected = false
        let items = orgItems
        let paramItems = null
        if (this.filter && Object.keys(this.filter).length > 0) {
          if (this.filter.shareTerms && this.filter.shareTerms.length > 0) {
            items = this.getShareTermFilteredData(orgItems)
            isShareFilterSelected = true
          }
          if (isShareFilterSelected) {
            paramItems = JSON.parse(JSON.stringify(items))
          } else {
            paramItems = JSON.parse(JSON.stringify(orgItems))
          }
          if (this.filter.financingRounds && this.filter.financingRounds.length > 0) {
            items = this.getRoundFilteredData(paramItems)
          }
          if (this.filter.status && this.filter.status.length > 0) {
            items = this.getStatusFilteredData(paramItems)
          }
        }
        this.updatedItems = items
      },
      getShareTermFilteredData (orgItems) {
        let items = []
        this.filter.shareTerms.forEach((id) => {
          const data = orgItems.filter((item) => {
            if (item.share_terms_id === id) {
              return item
            }
          })
          items = [...new Set(items.concat(data))]
        })
        return items
      },
      getRoundFilteredData (orgItems) {
        let items = []
        this.filter.financingRounds.forEach((id) => {
          const data = orgItems.filter((item) => {
            if (item.financing_round_id === id) {
              return item
            }
          })
          items = [...new Set(items.concat(data))]
        })
        return items
      },
      getStatusFilteredData (orgItems) {
        let items = []
        this.filter.status.forEach((status) => {
          const data = orgItems.filter((item) => {
            if ((status === this.CONSTS.DRAFTED_SECURITY_STATUS.VALID && item.is_valid) || (status === this.CONSTS.DRAFTED_SECURITY_STATUS.INVALID && !item.is_valid)) {
              return item
            }
          })
          items = [...new Set(items.concat(data))]
        })
        return items
      },
      selectAllToggle (props) {
        if (this.selected.length !== this.filteredData.length - this.disabledCount) {
          this.selected = []
          props.items.forEach(item => {
            if (item.is_valid) {
              this.selected.push(item)
              item.selected = true
            } else {
              item.selected = false
            }
          })
        } else this.selected = []
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .v-data-table > .v-data-table__wrapper > table {
    thead {
      tr {
        th {
          vertical-align: top;
        }
      }
    }
    tbody {
      tr {
        height: 70px !important;
        td {
          vertical-align: middle;
          padding: 8px!important;
        }
      }
    }
  }
</style>
