<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ valid }"
      class="ml-auto"
    >
      <!-- Existing/New Holder Form Section -->
      <holders-selection-form
        v-model="holder"
        :title="'Select Holder'"
        :holders-list="holdersList"
        @update="updateHolderId"
      />

      <!-- Warrant Details Section -->
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Warrant term'"
            :rules="{ required: true }"
          >
            <common-input-select
              v-model="formData.warrant_terms_id"
              label="Warrant Term"
              placeholder="Select warrant term"
              :items="warrantTermsList"
              item-text="name"
              item-value="id"
              :error-messages="errors"
              @change="setPrefilledData"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Financing round'"
            :rules="{ required: true }"
          >
            <common-input-select
              v-model="formData.financing_round_id"
              label="Financing Round"
              placeholder="Select financing round"
              :items="financingRoundList"
              item-text="name"
              item-value="id"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Granted warrants'"
            :rules="{required: true, min_value: 0}"
          >
            <common-input-currency-input
              v-model="formData.number"
              label="Granted warrants"
              color="primary"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Exercise price per share'"
            :rules="{ required: true, min_value: 0 }"
          >
            <common-input-currency-input
              v-model="formData.excercisePrice"
              label="Exercise price per share"
              color="primary"
              :error-messages="errors"
              :options="{ locale: 'en-US', precision: 2 }"
              prepend-inner-icon="mdi-currency-usd"
            />
          </validation-provider>
        </v-col>

        <v-col
          cols="12"
          lg="4"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Grant date'"
            :rules="{ required: true }"
          >
            <input-template-date
              v-model="grantedOn"
              :mask="'##/##/####'"
              :input-templ="{label: 'Grant date', mandatory: true}"
              :error-messages="errors"
              clearable
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Expiration date'"
            :rules="{ required: true }"
          >
            <input-template-date
              v-model="expiresOn"
              :mask="'##/##/####'"
              :input-templ="{label: 'Expiration date', mandatory: true}"
              :error-messages="errors"
              clearable
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Board approval date'"
            :rules="{ required: true }"
          >
            <input-template-date
              v-model="boardApprovedOn"
              :mask="'##/##/####'"
              :input-templ="{label: 'Board approval date', mandatory: true}"
              :error-messages="errors"
              clearable
            />
          </validation-provider>
        </v-col>

        <v-col
          cols="12"
          md="6"
          lg="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Stock registration name'"
            :rules="{ required: true }"
          >
            <common-input-text-field
              v-model="stockRegistrationName"
              label="Stock Registration Name"
              color="primary"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <notes-and-documents-form
            :id="warrantDetails.issuedWarrantId"
            :type="CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANTS"
            :internal-dialog="internalDialog"
            :mode="'edit'"
            :is-submit="isSubmit"
            @navToList="navToList"
          />
        </v-col>
      </v-row>
      <!-- Form Buttons Section -->
      <v-row>
        <v-col>
          <v-divider />
          <div class="mt-2 d-flex justify-space-between">
            <common-trs-btn
              type="secondary"
              medium
              class="mr-2"
              text
              @click="$emit('close')"
            >
              Cancel
            </common-trs-btn>
            <common-trs-btn
              type="primary"
              medium
              class="white--text"
              text
              :disabled="!valid"
              :loading="loading"
              @click="save"
            >
              Save
            </common-trs-btn>
          </div>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'
  import HoldersSelectionForm from '@/components/common/captable/components/forms/HoldersSelectionForm'
  import NotesAndDocumentsForm from '@/components/common/captable/components/forms/NotesAndDocumentsForm.vue'

  import * as captableService from '@/components/common/captable/services/captable'
  import * as captableAdminService from '@/services/captable-admin-service'

  export default {
    name: 'ModifyWarrant',

    components: {
      HoldersSelectionForm,
      InputTemplateDate,
      NotesAndDocumentsForm,
    },

    props: {
      warrantDetails: {
        type: Object,
        required: true,
        default: () => {},
      },
      holdersList: {
        type: Array,
        required: true,
        default: () => [],
      },
      warrantTermsList: {
        type: Array,
        required: true,
      },
      eipList: {
        type: Array,
        required: true,
      },
      financingRoundList: {
        type: Array,
        required: true,
      },
      internalDialog: {
        type: Boolean,
        default: false,
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      holder: {},
      formData: {},
      loading: false,
      grantedOn: '',
      expiresOn: '',
      boardApprovedOn: '',
      isSubmit: false,
      stockRegistrationName: '',
    }),

    computed: {
      securityDetails () {
        return {
          name: this.warrantDetails.shareName || '',
          numberOfSharesGranted: this.warrantDetails.number || 0,
        }
      },
      holderDetails () {
        return {
          name: this.warrantDetails.holderName || '',
          email: this.warrantDetails.email || '',
          id: this.warrantDetails.holderId || '',
        }
      },
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      warrantDetails: {
        handler (val) {
          if (val) {
            this.formData = JSON.parse(JSON.stringify(this.warrantDetails))
            this.stockRegistrationName = this.formData.registration_name
            this.holder.to_holder_id = this.warrantDetails.holder_id
            this.grantedOn = val.granted_on ? moment(val.granted_on).format('MM/DD/YYYY') : ''
            this.expiresOn = val.expires_on ? moment(val.expires_on).format('MM/DD/YYYY') : ''
            this.boardApprovedOn = val.board_approved_on ? moment(val.board_approved_on).format('MM/DD/YYYY') : ''
          }
        },
        immediate: true,
      },
      grantedOn: {
        handler (val) {
          if (val) {
            this.formData.granted_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      expiresOn: {
        handler (val) {
          if (val) {
            this.formData.expires_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      boardApprovedOn: {
        handler (val) {
          if (val) {
            this.formData.warrantBoardApprovedOn = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      internalDialog: {
        handler (val) {
          if (val) {
            this.isSubmit = false
          } else {
            this.formData = JSON.parse(JSON.stringify(this.warrantDetails))
            this.stockRegistrationName = this.formData.registration_name
          }
        },
      },
      stockRegistrationName: {
        handler (val) {
          if (val) {
            this.formData.registration_name = val
          }
        },
        immediate: true,
      },
    },

    methods: {
      updateHolderId (val) {
        if (val.to_holder_id) {
          this.formData.holder_id = val.to_holder_id
        } else {
          this.formData.holder_id = null
          delete val.to_holder_id
          this.holder = val
        }
      },
      async save () {
        this.loading = true
        if (!this.formData.holder_id) {
          this.formData.holder_id = await this.createNewInvestor()
        }
        this.formData.number = parseInt(this.formData.number)
        this.formData.strike_price = this.formData.excercisePrice
        try {
          this.clearNullData()
          const details = {
            payload: this.formData,
            certificatePayload: {
              captableId: this.captableId,
              securityId: this.warrantDetails.issuedWarrantId,
              termDocType: this.CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANT_TERMS,
              securityDocType: this.CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANTS,
              termID: this.warrantDetails.warrant_terms_id,
              registrationName: this.warrantDetails.registration_name,
              issuedNumber: this.formData.number,
            },
          }
          if (this.warrantDetails.is_issued && !this.isDraft) {
            await captableAdminService.createServiceRequest(this.CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANTS, this.CONSTS.ISSUED_SECURITY_ACTIONS.MODIFY, this.captableId, this.warrantDetails.issuedWarrantId, details)
          } else {
            await captableAdminService.approveServiceRequest(this.CONSTS.ISSUED_SECURITY_ACTIONS.MODIFY, this.CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANTS, this.captableId, details, this.isDraft)
          }
          this.isSubmit = true
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to modify warrant. Please try again later.',
          })
        }
      },
      navToList () {
        this.loading = false
        this.$refs.observer.reset()
        this.$emit('close')
        this.$emit('reload')
        this.$store.dispatch('app/message', { text: this.warrantDetails.is_issued ? captableAdminService.getServiceRequestSuccessMessage() : 'Warrant modified successfully.' })
      },
      async createNewInvestor () {
        try {
          const resp = await captableService.draftProfile(this.captableId, this.holder)
          const id = resp && resp.data.id
          await captableService.publishProfile(this.captableId, id)
          return id
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: `Sorry! Unable to transfer your share to ${this.holder.name}. Please try again later.`,
          })
        }
      },
      clearNullData () {
        Object.keys(this.formData).forEach((key) => {
          if (this.formData[key] === null || this.formData[key] === undefined) {
            delete this.formData[key]
          }
        })
      },
      setPrefilledData (val) {
        if (val) {
          const warrantTerm = this.warrantTermsList.find(term => term.id === val)
          this.stockRegistrationName = `${this.CONSTS.SECURITIES_PREFIX.WARRANT}-`
          if (warrantTerm.board_approved_on) {
            this.boardApprovedOn = moment(warrantTerm.board_approved_on).format('MM/DD/YYYY')
          } else {
            this.boardApprovedOn = ''
          }
        }
      },
    },
  }
</script>
