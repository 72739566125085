var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('common-trs-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredHoldersData,"item-key":"issuedConvertibleId","loading":_vm.loading,"hide-default-footer":"","show-select":_vm.isIssueAllowed && _vm.isSelectable},on:{"toggle-select-all":_vm.selectAllToggle},scopedSlots:_vm._u([{key:"header.convertibleName",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"trsText--text text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(header.text))])]}},{key:"header.shareTermDetail",fn:function(ref){
var header = ref.header;
return [_c('span',[_c('div',{staticClass:"trsText--text text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(header.text))]),_c('span',{staticClass:"trsText--text text-body-2 mt-1"},[_vm._v(" "+_vm._s(header.text2)+" ")])])]}},{key:"header.holderName",fn:function(ref){
var header = ref.header;
return [_c('span',[_c('div',{staticClass:"trsText--text text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(header.text))]),_c('span',{staticClass:"trsText--text text-body-2 mt-1"},[_vm._v(" "+_vm._s(header.text2)+" ")])])]}},{key:"header.number",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"trsText--text text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(header.text))])]}},{key:"header.issued_on",fn:function(ref){
var header = ref.header;
return [_c('span',[_c('div',{staticClass:"trsText--text text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(header.text))]),_c('span',{staticClass:"trsText--text text-body-2 mt-1"},[_vm._v(" "+_vm._s(header.text2)+" ")])])]}},{key:"header.maturity_date",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"trsText--text text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(header.text))])]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"trsText--text text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(header.text))])]}},{key:"header.action",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"trsText--text text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(header.text))])]}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"value":isSelected && item.is_valid,"readonly":!item.is_valid,"disabled":!item.is_valid,"ripple":false},on:{"input":function($event){return select($event)}}})]}},{key:"item.convertibleName",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"trs--text text-body-1 no-gutters"},[_c('v-col',{staticClass:"d-flex align-center mr-2",attrs:{"cols":"4"}},[_c('share-term-badge',{staticClass:"mx-1",attrs:{"type":item.convertible_type,"show-single":true}})],1),_c('v-col',[_vm._v(" "+_vm._s(item.convertibleName)+" ")])],1)]}},{key:"item.shareTermDetail",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticClass:"trs--text text-body-1 text-capitalize"},[_vm._v(" "+_vm._s(item.shareTermName)+" ")]),_c('div',{staticClass:"trsText--text text-body-2 mt-1"},[_vm._v(" "+_vm._s(_vm.$date(item.shareTermBoardApprovedOn))+" ")])])]}},{key:"item.holderName",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticClass:"trs--text text-body-1"},[_vm._v(" "+_vm._s(item.holderName)+" ")]),_c('div',{staticClass:"trsText--text text-body-2 mt-1"},[_vm._v(" "+_vm._s(item.registration_name)+" ")])])]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trs--text text-body-1"},[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(item.amount))+" ")])]}},{key:"item.issued_on",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticClass:"trs--text text-body-1"},[_vm._v(" "+_vm._s(_vm.$date(item.issued_on ))+" ")]),_c('div',{staticClass:"trsText--text text-body-2 mt-1"},[_vm._v(" "+_vm._s(_vm.$date(item.convertibleBoardApprovedOn ))+" ")])])]}},{key:"item.maturity_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticClass:"trs--text text-body-1"},[_vm._v(" "+_vm._s(_vm.$date(item.matures_on ))+" ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('status-chip',{attrs:{"is-valid":item.is_valid}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('convertibles-actions',{attrs:{"convertible-details":item,"holders-list":_vm.investors,"convertible-terms-list":_vm.convertibleTerms,"eip-list":_vm.eips,"financing-round-list":_vm.financingRounds,"is-selectable":_vm.isSelectable},on:{"delete":function($event){return _vm.$emit('delete',item)},"reload":function($event){return _vm.$emit('reload')},"issue":function($event){return _vm.$emit('issue',item)}}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-divider'),_c('common-trs-data-table-list-pager',{staticClass:"ml-2",attrs:{"length":_vm.pages},model:{value:(_vm.pager),callback:function ($$v) {_vm.pager=$$v},expression:"pager"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }