<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ valid }"
      class="ml-auto"
    >
      <!-- Summary View Section -->
      <securities-summary-view
        :security-type="securityType"
        :security-details="securityDetails"
        :holder-details="holderDetails"
      />

      <!-- Cancel Date Section -->
      <v-row class="mt-5">
        <v-col
          cols="6"
          class="pr-0"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'No. of options'"
            :rules="numOfOptionsValidation"
          >
            <common-input-currency-input
              v-model="formData.number"
              label="No. of Options"
              color="primary"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              @input="calculate"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="6"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            :name="'Termination date'"
          >
            <input-template-date
              v-model="terminationDate"
              :mask="'##/##/####'"
              :input-templ="{label: 'Termination date', mandatory: true}"
              :error-messages="errors"
              :hide-details="errors.length === 0"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col
          cols="6"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Employee Terminated On'"
          >
            <input-template-date
              v-model="employeeTerminatedOn"
              :mask="'##/##/####'"
              :input-templ="{label: 'Employee terminated on', mandatory: true}"
              :error-messages="errors"
              :hide-details="errors.length === 0"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <!-- Form Buttons Section -->
      <v-row class="mt-0">
        <v-col>
          <v-divider />
          <div class="mt-2 d-flex justify-space-between">
            <common-trs-btn
              type="secondary"
              medium
              class="mr-2"
              text
              @click="$emit('close')"
            >
              Cancel
            </common-trs-btn>
            <common-trs-btn
              type="primary"
              medium
              class="white--text"
              text
              :disabled="!valid"
              :loading="loading"
              @click="terminate"
            >
              Submit
            </common-trs-btn>
          </div>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'
  import SecuritiesSummaryView from '@/components/common/captable/components/SecuritiesSummaryView'

  import * as captableAdminService from '@/services/captable-admin-service'

  export default {
    name: 'TerminateOption',

    components: {
      SecuritiesSummaryView,
      InputTemplateDate,
    },

    props: {
      optionDetails: {
        type: Object,
        required: true,
        default: () => {},
      },
      holdersList: {
        type: Array,
        required: true,
        default: () => [],
      },
      isModalClosed: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      formData: {},
      loading: false,
      numOfOptionsValidation: {},
      calculatedAmount: null,
      terminationDate: '',
      employeeTerminatedOn: '',
    }),

    computed: {
      securityType () {
        return this.optionDetails.type || this.CONSTS.SHARE_TERM_TYPES.COMMON
      },
      securityDetails () {
        return {
          name: 'Options',
          numberOfSharesGranted: this.optionDetails.number || 0,
        }
      },
      holderDetails () {
        return {
          name: this.optionDetails.holderName || '',
          email: this.optionDetails.email || '',
          id: this.optionDetails.holderId || '',
        }
      },
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      optionDetails: {
        handler (val) {
          if (val) {
            this.numOfOptionsValidation = {
              required: true,
              max_value: val.number,
              min_value: 0,
            }
            this.terminationDate = val.terminated_on ? moment(val.terminated_on).format('MM/DD/YYYY') : ''
            this.employeeTerminatedOn = val.employee_terminated_on ? moment(val.employee_terminated_on).format('MM/DD/YYYY') : ''
          }
        },
        immediate: true,
      },
      isModalClosed: {
        handler (val) {
          if (val) {
            this.formData = {}
            this.calculatedAmount = null
            this.$refs.observer.reset()
            this.isModalClosed = false
          }
        },
        immediate: true,
      },
      terminationDate: {
        handler (val) {
          if (val) {
            this.formData.terminated_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      employeeTerminatedOn: {
        handler (val) {
          if (val) {
            this.formData.employee_terminated_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
    },

    methods: {
      async terminate () {
        this.loading = true
        if (this.formData.terminated_on > this.optionDetails.granted_on) {
          try {
            const details = {
              payload: this.formData,
              certificatePayload: {
                captableId: this.captableId,
                securityId: this.optionDetails.issuedOptionId,
                termDocType: this.CONSTS.NOTES_DOCUMENT_API_TYPE.EQUITY_INCENTIVE_PLANS,
                securityDocType: this.CONSTS.NOTES_DOCUMENT_API_TYPE.OPTIONS,
                termID: this.optionDetails.equity_incentive_plan_id,
                registrationName: this.optionDetails.registration_name,
                issuedNumber: this.formData.number,
              },
            }
            await captableAdminService.createServiceRequest(this.CONSTS.NOTES_DOCUMENT_API_TYPE.OPTIONS, this.CONSTS.ISSUED_SECURITY_ACTIONS.TERMINATE, this.captableId, this.optionDetails.issuedOptionId, details)
            this.loading = false
            this.$emit('close')
            this.$emit('reload')
            this.$store.dispatch('app/message', { text: captableAdminService.getServiceRequestSuccessMessage() })
          } catch (err) {
            this.loading = false
            this.$store.dispatch('app/handleError', {
              err,
              message: 'Sorry! Unable to terminate your option. Please try again later.',
            })
          }
        } else {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            message: 'Sorry! Please select terminate date greater than grant date.',
          })
        }
      },
      calculate () {
        this.calculatedAmount = this.formData.number * this.formData.price_per_share
      },
    },
  }
</script>
