<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ valid }"
      class="ml-auto"
    >
      <!-- Existing/New Holder Form Section -->
      <holders-selection-form
        v-model="holder"
        :title="'Select Holder'"
        :holders-list="holdersList"
        @update="updateHolderId"
      />
      <!-- Share Details Section -->
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Share term'"
            :rules="{ required: true }"
          >
            <common-input-select
              v-model="formData.share_terms_id"
              label="Share Term"
              placeholder="Select share term"
              :items="shareTermsList"
              item-text="name"
              item-value="id"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              @change="setPrefilledData"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Equity incentive plan'"
          >
            <common-input-select
              v-model="formData.equity_incentive_plan_id"
              label="Equity incentive plan (Optional)"
              placeholder="Select equity incentive plan"
              :items="eipList"
              item-text="name"
              item-value="id"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              clearable
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Financing round'"
          >
            <common-input-select
              v-model="formData.financing_round_id"
              label="Financing Round (Optional)"
              placeholder="Select financing round"
              :items="financingRoundList"
              item-text="name"
              item-value="id"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              clearable
              @click:clear="resetPPR"
              @change="setPPR"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          md="12"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Stock registration name'"
            :rules="{ required: true }"
          >
            <common-input-text-field
              v-model="stockRegistrationName"
              label="Stock Registration Name"
              color="primary"
              :error-messages="errors"
              :hide-details="errors.length === 0"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Grant date'"
          >
            <input-template-date
              v-model="grantDate"
              :mask="'##/##/####'"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              :input-templ="{label: 'Grant date'}"
              clearable
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Board approval date'"
            :rules="{ required: true }"
          >
            <input-template-date
              v-model="boardApprovedOn"
              :mask="'##/##/####'"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              :input-templ="{label: 'Board approval date', mandatory: true}"
              clearable
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            :name="'Granted shares'"
            :rules="{required: true, min_value: 0}"
          >
            <common-input-currency-input
              v-model="formData.number"
              label="Granted Shares"
              color="primary"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              @input="calculate"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="1"
          class="text-center mt-3"
        >
          X
        </v-col>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            :name="'Price per Share'"
            :rules="{ required: true, min_value: 0 }"
          >
            <common-input-currency-input
              v-model="formData.price_per_share"
              label="Price per share"
              color="primary"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              :options="{ locale: 'en-US', precision: 2 }"
              prepend-inner-icon="mdi-currency-usd"
              @input="calculate"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <span v-if="!calculatedAmount">= NA</span>
          <span v-else>= {{ $currencyWithPrecision(calculatedAmount) }} </span>
          <div class="trsText--text text-body-2 text--lighten-1 mt-1">
            Invested capital
          </div>
        </v-col>
      </v-row>

      <!-- Vesting Section -->
      <v-row>
        <v-col class="pb-0">
          <common-vesting-form
            v-model="formData"
            :is-edit="true"
            @update="updateVestingSchedule"
          />
        </v-col>
      </v-row>

      <!-- Notes & Document Section -->
      <v-row>
        <v-col cols="12">
          <notes-and-documents-form
            :id="shareDetails.issuedShareId"
            :type="CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES"
            :internal-dialog="internalDialog"
            :mode="'edit'"
            :is-submit="isSubmit"
            @navToList="navToList"
          />
        </v-col>
      </v-row>

      <!-- Form Buttons Section -->
      <v-row>
        <v-col>
          <v-divider />
          <div class="mt-2 d-flex justify-space-between">
            <common-trs-btn
              type="secondary"
              medium
              class="mr-2"
              text
              @click="$emit('close')"
            >
              Cancel
            </common-trs-btn>
            <common-trs-btn
              type="primary"
              medium
              class="white--text"
              text
              :disabled="!valid"
              :loading="loading"
              @click="save"
            >
              Save
            </common-trs-btn>
          </div>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'
  import HoldersSelectionForm from '@/components/common/captable/components/forms/HoldersSelectionForm'
  import CommonVestingForm from '@/components/common/captable/components/forms/CommonVestingForm'
  import NotesAndDocumentsForm from '@/components/common/captable/components/forms/NotesAndDocumentsForm.vue'

  import * as captableService from '@/components/common/captable/services/captable'
  import * as captableAdminService from '@/services/captable-admin-service'

  export default {
    name: 'ModifyShare',

    components: {
      HoldersSelectionForm,
      CommonVestingForm,
      InputTemplateDate,
      NotesAndDocumentsForm,
    },

    props: {
      shareDetails: {
        type: Object,
        required: true,
        default: () => {},
      },
      holdersList: {
        type: Array,
        required: true,
        default: () => [],
      },
      shareTermsList: {
        type: Array,
        required: true,
      },
      eipList: {
        type: Array,
        required: true,
      },
      financingRoundList: {
        type: Array,
        required: true,
      },
      internalDialog: {
        type: Boolean,
        default: false,
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      holder: {},
      formData: {},
      loading: false,
      calculatedAmount: null,
      grantDate: '',
      boardApprovedOn: '',
      isSubmit: false,
      stockRegistrationName: '',
    }),

    computed: {
      securityType () {
        return this.shareDetails.type || this.CONSTS.SHARE_TERM_TYPES.COMMON
      },
      securityDetails () {
        return {
          name: this.shareDetails.shareName || '',
          numberOfSharesGranted: this.shareDetails.number || 0,
        }
      },
      holderDetails () {
        return {
          name: this.shareDetails.holderName || '',
          email: this.shareDetails.email || '',
          id: this.shareDetails.holderId || '',
        }
      },
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      shareDetails: {
        handler (val) {
          if (val) {
            this.formData = JSON.parse(JSON.stringify(this.shareDetails))
            this.stockRegistrationName = this.formData.registration_name
            this.holder.to_holder_id = this.shareDetails.holder_id
            this.grantDate = moment(this.shareDetails.granted_on).format('MM/DD/YYYY')
            this.boardApprovedOn = moment(this.shareDetails.board_approved_on).format('MM/DD/YYYY')
            this.calculate()
          }
        },
        immediate: true,
      },
      grantDate: {
        handler (val) {
          if (val) {
            this.shareDetails.granted_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      boardApprovedOn: {
        handler (val) {
          if (val) {
            this.shareDetails.board_approved_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      internalDialog: {
        handler (val) {
          if (val) {
            this.isSubmit = false
          } else {
            this.formData = JSON.parse(JSON.stringify(this.shareDetails))
            this.stockRegistrationName = this.formData.registration_name
          }
        },
      },
      stockRegistrationName: {
        handler (val) {
          if (val) {
            this.formData.registration_name = val
          }
        },
        immediate: true,
      },
    },

    methods: {
      updateHolderId (val) {
        if (val.to_holder_id) {
          this.formData.holder_id = val.to_holder_id
        } else {
          this.formData.holder_id = null
          delete val.to_holder_id
          this.holder = val
        }
      },
      async save () {
        this.loading = true
        if (!this.formData.holder_id) {
          this.formData.holder_id = await this.createNewInvestor()
        }
        this.formData.number = parseInt(this.formData.number)
        if (this.formData.equity_incentive_plan_id && this.formData.financing_round_id) {
          this.$store.dispatch('app/handleError', {
            message: 'Sorry! Please set only one of Financing round Id or Eip Id.',
          })
          this.loading = false
        } else {
          if (this.formData.vesting_schedule && this.formData.vesting_schedule.custom && this.formData.vesting_schedule.custom.length > 0) {
            const result = this.formData.vesting_schedule.custom.map(item => {
              return { ...item, percent: item.percent / 100 }
            })
            this.formData.vesting_schedule.custom = result
          } else {
            if (this.formData.vesting_schedule && this.formData.vesting_schedule.time_based && this.formData.vesting_schedule.time_based.cliff) {
              this.formData.vesting_schedule.time_based.cliff = { ...this.formData.vesting_schedule.time_based.cliff, percent: this.formData.vesting_schedule.time_based.cliff.percent / 100 }
            }
          }
          try {
            this.clearNullData()
            if (this.formData.equity_incentive_plan_id === null) delete this.formData.equity_incentive_plan_id
            if (this.formData.financing_round_id === null) delete this.formData.financing_round_id
            const details = {
              payload: this.formData,
              certificatePayload: {
                captableId: this.captableId,
                securityId: this.shareDetails.issuedShareId,
                termDocType: this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARE_TERMS,
                securityDocType: this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES,
                termID: this.shareDetails.share_terms_id,
                registrationName: this.shareDetails.registration_name,
                issuedNumber: this.formData.number,
              },
            }
            if (this.shareDetails.is_issued && !this.isDraft) {
              await captableAdminService.createServiceRequest(this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES, this.CONSTS.ISSUED_SECURITY_ACTIONS.MODIFY, this.captableId, this.shareDetails.issuedShareId, details)
            } else {
              await captableAdminService.approveServiceRequest(this.CONSTS.ISSUED_SECURITY_ACTIONS.MODIFY, this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES, this.captableId, details, this.isDraft)
            }
            this.isSubmit = true
          } catch (err) {
            this.loading = false
            this.$store.dispatch('app/handleError', {
              err,
              message: 'Sorry! Unable to modify your share. Please try again later.',
            })
          }
        }
      },
      navToList () {
        this.loading = false
        this.$refs.observer.reset()
        this.$emit('close')
        this.$emit('reload')
        this.$store.dispatch('app/message', { text: this.shareDetails.is_issued ? captableAdminService.getServiceRequestSuccessMessage() : 'Share modified successfully.' })
      },
      calculate () {
        this.calculatedAmount = this.formData.number * this.formData.price_per_share
      },
      async createNewInvestor () {
        try {
          const resp = await captableService.draftProfile(this.captableId, this.holder)
          const id = resp && resp.data.id
          await captableService.publishProfile(this.captableId, id)
          return id
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: `Sorry! Unable to transfer your share to ${this.holder.name}. Please try again later.`,
          })
        }
      },
      clearNullData () {
        Object.keys(this.formData).forEach((key) => {
          if (this.formData[key] === null || this.formData[key] === undefined) {
            delete this.formData[key]
          }
          if (this.formData.vesting_schedule) {
            Object.keys(this.formData.vesting_schedule).forEach((key) => {
              if (this.formData.vesting_schedule[key] === undefined || this.formData.vesting_schedule[key] === null || Object.keys(this.formData.vesting_schedule[key]).length === 0) {
                delete this.formData.vesting_schedule[key]
              }
            })
          }
        })
      },
      updateVestingSchedule (val) {
        this.formData = val
      },
      resetPPR () {
        this.formData.price_per_share = 0
      },
      setPPR (val) {
        const PPR = this.financingRoundList.find(round => round.id === val)
        this.formData.price_per_share = PPR.price_per_share
        this.calculate()
      },
      setPrefilledData (val) {
        if (val) {
          const sharedTerm = this.shareTermsList.find(term => term.id === val)
          if (sharedTerm.type) {
            this.stockRegistrationName = `${this.prefix(sharedTerm.type)}-`
          }
          if (sharedTerm.board_approved_on) {
            this.boardApprovedOn = moment(sharedTerm.board_approved_on).format('MM/DD/YYYY')
          } else {
            this.boardApprovedOn = ''
          }
        }
      },
      prefix (type) {
        if (type === this.CONSTS.SHARE_TERM_TYPES.COMMON) {
          return this.CONSTS.SECURITIES_PREFIX.COMMON
        } else if (type === this.CONSTS.SHARE_TERM_TYPES.PREFERRED) {
          return this.CONSTS.SECURITIES_PREFIX.PREFERRED
        }
      },
    },
  }
</script>
