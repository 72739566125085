var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center",staticStyle:{"width":"50px"}},[_c('v-menu',{ref:"menu",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('common-trs-btn',_vm._g(_vm._b({staticClass:"mx-1 rounded-0",attrs:{"light":"","text":"","type":"secondary","icon":""}},'common-trs-btn',attrs,false),on),[_c('v-icon',{staticClass:"black--text"},[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('securities-action-modal',{attrs:{"title":_vm.isDraft ? 'View Draft': 'Warrants Summary',"warrant-details":_vm.warrantDetails,"holders-list":_vm.holdersList,"warrant-terms-list":_vm.warrantTermsList,"financing-round-list":_vm.financingRoundList,"max-width":1280,"is-draft":_vm.isDraft,"action-type":_vm.CONSTS.ISSUED_SECURITY_ACTIONS.VIEW,"eip-list":_vm.eipList},on:{"close":_vm.closeMenu,"issueHandler":function($event){return _vm.$emit('issue')},"reload":function($event){return _vm.$emit('reload')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.isDraft ? 'View': 'View Summary')+" ")])]}}])})],1)],1),_c('v-list-item',{directives:[{name:"feature",rawName:"v-feature",value:(_vm.CONSTS.CAPTABLE_FEATURES.EDIT_SECURITY),expression:"CONSTS.CAPTABLE_FEATURES.EDIT_SECURITY"}]},[_c('v-list-item-title',[_c('securities-action-modal',{attrs:{"title":_vm.isDraft ? 'Edit Warrants':'Modify Warrants',"warrant-details":_vm.warrantDetails,"holders-list":_vm.holdersList,"action-type":_vm.CONSTS.ISSUED_SECURITY_ACTIONS.MODIFY,"warrant-terms-list":_vm.warrantTermsList,"eip-list":_vm.eipList,"financing-round-list":_vm.financingRoundList,"is-draft":_vm.isDraft},on:{"reload":function($event){return _vm.$emit('reload')},"close":_vm.closeMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({},Object.assign({}, on)),[_vm._v(" "+_vm._s(_vm.isDraft ? 'Edit': 'Modify')+" ")])]}}])})],1)],1),(_vm.isDraft && _vm.warrantDetails.is_valid)?_c('v-list-item',{directives:[{name:"feature",rawName:"v-feature",value:(_vm.CONSTS.CAPTABLE_FEATURES.ISSUE_SECURITY),expression:"CONSTS.CAPTABLE_FEATURES.ISSUE_SECURITY"}]},[_c('v-list-item-title',[_c('span',{staticClass:"primary--text cursor-pointer",on:{"click":function($event){return _vm.$emit('issue')}}},[_vm._v(" Issue ")])])],1):_vm._e(),(!_vm.isDraft)?_c('v-list-item',{directives:[{name:"feature",rawName:"v-feature",value:(_vm.CONSTS.CAPTABLE_FEATURES.RAISE_SECURITY_ACTION_REQUEST),expression:"CONSTS.CAPTABLE_FEATURES.RAISE_SECURITY_ACTION_REQUEST"}]},[_c('v-list-item-title',[_c('securities-action-modal',{attrs:{"title":'Exercise Warrants',"holders-list":_vm.holdersList,"warrant-details":_vm.warrantDetails,"action-type":_vm.CONSTS.ISSUED_SECURITY_ACTIONS.EXERCISE},on:{"reload":function($event){return _vm.$emit('reload')},"close":_vm.closeMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({},Object.assign({}, on)),[_vm._v(" Exercise ")])]}}],null,false,114850049)})],1)],1):_vm._e(),(!_vm.isDraft)?_c('v-list-item',{directives:[{name:"feature",rawName:"v-feature",value:(_vm.CONSTS.CAPTABLE_FEATURES.RAISE_SECURITY_ACTION_REQUEST),expression:"CONSTS.CAPTABLE_FEATURES.RAISE_SECURITY_ACTION_REQUEST"}]},[_c('v-list-item-title',[_c('securities-action-modal',{attrs:{"title":'Cancel Warrants',"holders-list":_vm.holdersList,"warrant-details":_vm.warrantDetails,"action-type":_vm.CONSTS.ISSUED_SECURITY_ACTIONS.CANCEL},on:{"reload":function($event){return _vm.$emit('reload')},"close":_vm.closeMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({},Object.assign({}, on)),[_vm._v(" Cancel ")])]}}],null,false,4019981649)})],1)],1):_vm._e(),_c('v-list-item',{directives:[{name:"feature",rawName:"v-feature",value:(_vm.CONSTS.CAPTABLE_FEATURES.DELETE_SECURITY),expression:"CONSTS.CAPTABLE_FEATURES.DELETE_SECURITY"}]},[_c('v-list-item-title',[_c('common-app-confirm',{attrs:{"title":"Delete Warrant","message":"Are you sure you want to delete this warrant?","yes-button":"Yes","no-button":"Cancel"},on:{"yes":_vm.deleteIssuedShare,"close":_vm.closeMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({},Object.assign({}, on)),[_vm._v(" Delete ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }