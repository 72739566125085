var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",staticClass:"ml-auto",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('holders-selection-form',{attrs:{"title":'Select Holder',"holders-list":_vm.holdersList},on:{"update":_vm.updateHolderId},model:{value:(_vm.holder),callback:function ($$v) {_vm.holder=$$v},expression:"holder"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Equity incentive plan',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Equity incentive plan","placeholder":"Select equity incentive plan","items":_vm.eipList,"item-text":"name","item-value":"id","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.formData.equity_incentive_plan_id),callback:function ($$v) {_vm.$set(_vm.formData, "equity_incentive_plan_id", $$v)},expression:"formData.equity_incentive_plan_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Share term',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Share Term","placeholder":"Select share term","items":_vm.shareTermsList,"item-text":"name","item-value":"id","error-messages":errors,"hide-details":errors.length === 0},on:{"change":_vm.setPrefilledData},model:{value:(_vm.formData.share_terms_id),callback:function ($$v) {_vm.$set(_vm.formData, "share_terms_id", $$v)},expression:"formData.share_terms_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Granted shares',"rules":{required: true, min_value: 0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Granted Shares","color":"primary","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.formData.number),callback:function ($$v) {_vm.$set(_vm.formData, "number", $$v)},expression:"formData.number"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Exercise price per Share',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Exercise price per share","color":"primary","error-messages":errors,"hide-details":errors.length === 0,"options":{ locale: 'en-US', precision: 2 },"prepend-inner-icon":"mdi-currency-usd"},model:{value:(_vm.formData.exercise_price),callback:function ($$v) {_vm.$set(_vm.formData, "exercise_price", $$v)},expression:"formData.exercise_price"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":'Grant date',"rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"input-templ":{label: 'Grant date', mandatory: true},"error-messages":errors,"hide-details":errors.length === 0,"clearable":""},model:{value:(_vm.grantedOn),callback:function ($$v) {_vm.grantedOn=$$v},expression:"grantedOn"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":'Expiration date'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"input-templ":{label: 'Expiration date'},"error-messages":errors,"hide-details":errors.length === 0,"clearable":""},model:{value:(_vm.expiresOn),callback:function ($$v) {_vm.expiresOn=$$v},expression:"expiresOn"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":'Board approval date',"rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"input-templ":{label: 'Board approval date', mandatory: true},"error-messages":errors,"hide-details":errors.length === 0,"clearable":""},model:{value:(_vm.boardApprovedOn),callback:function ($$v) {_vm.boardApprovedOn=$$v},expression:"boardApprovedOn"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Stock registration name',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Stock Registration Name","color":"primary","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.stockRegistrationName),callback:function ($$v) {_vm.stockRegistrationName=$$v},expression:"stockRegistrationName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Option type'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Option Type (Optional)","placeholder":"Select option type","items":_vm.optionTypes,"item-text":"title","item-value":"value","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('common-vesting-form',{attrs:{"is-edit":true},on:{"update":_vm.updateVestingSchedule},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)],1),_c('v-row',{staticClass:"mt-n5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('notes-and-documents-form',{attrs:{"id":_vm.optionDetails.issuedOptionId,"internal-dialog":_vm.internalDialog,"type":_vm.CONSTS.NOTES_DOCUMENT_API_TYPE.OPTIONS,"mode":'edit',"is-submit":_vm.isSubmit},on:{"navToList":_vm.navToList}})],1)],1),_c('v-row',[_c('v-col',[_c('v-divider'),_c('div',{staticClass:"mt-2 d-flex justify-space-between"},[_c('common-trs-btn',{staticClass:"mr-2",attrs:{"type":"secondary","medium":"","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('common-trs-btn',{staticClass:"white--text",attrs:{"type":"primary","medium":"","text":"","disabled":!valid,"loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }