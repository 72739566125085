<template>
  <v-container>
    <div class="text-center">
      <v-row>
        <v-col
          cols="12"
        >
          <search-bar
            v-model="search"
            :label="label"
          >
            <template v-slot:content>
              <v-row class="no-gutters">
                <v-col
                  cols="12"
                  md="4"
                  class="px-1"
                >
                  <common-input-select
                    v-model="filter.shareTerms"
                    label="Share Terms"
                    placeholder="Select Share Terms"
                    :items="shareTermsList"
                    item-text="name"
                    item-value="id"
                    multiple
                    clearable
                  />
                </v-col>
                <v-col
                  v-if="showDraft"
                  cols="12"
                  md="4"
                  class="px-1"
                >
                  <common-input-select
                    v-model="filter.status"
                    label="Status"
                    placeholder="Status"
                    :items="CONSTS.DRAFTED_SECURITY_STATUS_LIST"
                    item-text="title"
                    item-value="value"
                    multiple
                    clearable
                  />
                </v-col>
                <v-col
                  v-if="!showDraft"
                  cols="12"
                  md="4"
                  class="px-1"
                >
                  <common-input-select
                    v-model="filter.financingRounds"
                    label="Financing Round"
                    placeholder="Select Financing Round"
                    :items="financingRoundList"
                    item-text="name"
                    item-value="id"
                    multiple
                    clearable
                  />
                </v-col>
              </v-row>
            </template>
            <template v-slot:actions>
              <div
                v-if="showDraft"
                class="text-right"
              >
                <common-trs-btn
                  v-feature="CONSTS.CAPTABLE_FEATURES.ISSUE_SECURITY"
                  type="secondary"
                  medium
                  class="white--text mt-4 mr-3"
                  text
                  :disabled="!selectedShares || selectedShares.length == 0"
                  @click="issueShares"
                >
                  Issue Shares
                </common-trs-btn>
                <common-trs-btn
                  v-feature="CONSTS.CAPTABLE_FEATURES.DRAFT_SECURITY"
                  type="primary"
                  medium
                  class="white--text mt-4"
                  text
                  :to="{ name: 'DraftShare' }"
                >
                  <v-icon class="mr-2">
                    mdi-plus-circle
                  </v-icon>
                  Draft Shares
                </common-trs-btn>
              </div>
            </template>
          </search-bar>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col>
          <shares-data-table
            :all-shares="allShares"
            :share-terms="shareTermsList"
            :financing-rounds="financingRoundList"
            :eips="eipList"
            :investors="holdersList"
            :search="search"
            :loading="loading"
            :is-selectable="showDraft"
            :hide-fields="hideFields"
            :filter="filter"
            @sharesSelected="sharesSelected"
            @stopLoading="$emit('stopLoading')"
            @delete="deleteShare"
            @reload="$emit('reload')"
            @issue="issue"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  import SharesDataTable from './../data-tables/SharesDataTable'
  import searchBar from '@/components/common/captable/components/Search'

  export default {
    name: 'IssuedSharesTab',

    components: {
      searchBar,
      SharesDataTable,
    },

    props: {
      hideFields: {
        validate: () => true,
        default: () => [],
      },
      allShares: {
        type: Array,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      holdersList: {
        type: Array,
        required: true,
        default: () => [],
      },
      shareTermsList: {
        type: Array,
        required: true,
      },
      eipList: {
        type: Array,
        required: true,
      },
      financingRoundList: {
        type: Array,
        required: true,
      },
      showDraft: {
        type: Boolean,
        default: false,
      },
      clearFilters: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      label: 'Search Holders',
      search: '',
      selectedShares: [],
      filter: {},
    }),

    watch: {
      clearFilters: {
        handler (val) {
          if (val) {
            this.filter = {}
            this.search = ''
          }
        },
        immediate: true,
      },
    },

    methods: {
      sharesSelected (val) {
        this.selectedShares = val
      },

      issueShares () {
        this.$emit('issueShares', this.selectedShares)
      },
      deleteShare (val) {
        this.$emit('delete', val)
      },
      issue (val) {
        this.$emit('issue', val)
      },
    },
  }
</script>
