var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",staticClass:"ml-auto",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('holders-selection-form',{attrs:{"title":'Select Holder',"holders-list":_vm.holdersList},on:{"update":_vm.updateHolderId},model:{value:(_vm.holder),callback:function ($$v) {_vm.holder=$$v},expression:"holder"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":'Share term',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Share Term","placeholder":"Select share term","items":_vm.shareTermsList,"item-text":"name","item-value":"id","error-messages":errors,"hide-details":errors.length === 0},on:{"change":_vm.setPrefilledData},model:{value:(_vm.formData.share_terms_id),callback:function ($$v) {_vm.$set(_vm.formData, "share_terms_id", $$v)},expression:"formData.share_terms_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Equity incentive plan'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Equity incentive plan (Optional)","placeholder":"Select equity incentive plan","items":_vm.eipList,"item-text":"name","item-value":"id","error-messages":errors,"hide-details":errors.length === 0,"clearable":""},model:{value:(_vm.formData.equity_incentive_plan_id),callback:function ($$v) {_vm.$set(_vm.formData, "equity_incentive_plan_id", $$v)},expression:"formData.equity_incentive_plan_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Financing round'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Financing Round (Optional)","placeholder":"Select financing round","items":_vm.financingRoundList,"item-text":"name","item-value":"id","error-messages":errors,"hide-details":errors.length === 0,"clearable":""},on:{"click:clear":_vm.resetPPR,"change":_vm.setPPR},model:{value:(_vm.formData.financing_round_id),callback:function ($$v) {_vm.$set(_vm.formData, "financing_round_id", $$v)},expression:"formData.financing_round_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":'Stock registration name',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Stock Registration Name","color":"primary","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.stockRegistrationName),callback:function ($$v) {_vm.stockRegistrationName=$$v},expression:"stockRegistrationName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Grant date'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"hide-details":errors.length === 0,"input-templ":{label: 'Grant date'},"clearable":""},model:{value:(_vm.grantDate),callback:function ($$v) {_vm.grantDate=$$v},expression:"grantDate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Board approval date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"hide-details":errors.length === 0,"input-templ":{label: 'Board approval date', mandatory: true},"clearable":""},model:{value:(_vm.boardApprovedOn),callback:function ($$v) {_vm.boardApprovedOn=$$v},expression:"boardApprovedOn"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":'Granted shares',"rules":{required: true, min_value: 0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Granted Shares","color":"primary","error-messages":errors,"hide-details":errors.length === 0},on:{"input":_vm.calculate},model:{value:(_vm.formData.number),callback:function ($$v) {_vm.$set(_vm.formData, "number", $$v)},expression:"formData.number"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-center mt-3",attrs:{"cols":"1"}},[_vm._v(" X ")]),_c('v-col',[_c('validation-provider',{attrs:{"name":'Price per Share',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Price per share","color":"primary","error-messages":errors,"hide-details":errors.length === 0,"options":{ locale: 'en-US', precision: 2 },"prepend-inner-icon":"mdi-currency-usd"},on:{"input":_vm.calculate},model:{value:(_vm.formData.price_per_share),callback:function ($$v) {_vm.$set(_vm.formData, "price_per_share", $$v)},expression:"formData.price_per_share"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[(!_vm.calculatedAmount)?_c('span',[_vm._v("= NA")]):_c('span',[_vm._v("= "+_vm._s(_vm.$currencyWithPrecision(_vm.calculatedAmount))+" ")]),_c('div',{staticClass:"trsText--text text-body-2 text--lighten-1 mt-1"},[_vm._v(" Invested capital ")])])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('common-vesting-form',{attrs:{"is-edit":true},on:{"update":_vm.updateVestingSchedule},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('notes-and-documents-form',{attrs:{"id":_vm.shareDetails.issuedShareId,"type":_vm.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES,"internal-dialog":_vm.internalDialog,"mode":'edit',"is-submit":_vm.isSubmit},on:{"navToList":_vm.navToList}})],1)],1),_c('v-row',[_c('v-col',[_c('v-divider'),_c('div',{staticClass:"mt-2 d-flex justify-space-between"},[_c('common-trs-btn',{staticClass:"mr-2",attrs:{"type":"secondary","medium":"","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('common-trs-btn',{staticClass:"white--text",attrs:{"type":"primary","medium":"","text":"","disabled":!valid,"loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }