<template>
  <div
    style="width: 50px;"
    class="text-center"
  >
    <v-menu
      ref="menu"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <common-trs-btn
          light
          text
          type="secondary"
          class="mx-1 rounded-0"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="black--text">
            mdi-dots-vertical
          </v-icon>
        </common-trs-btn>
      </template>
      <v-list>
        <v-list-item
          v-if="isVestingAvailable"
          v-feature="CONSTS.CAPTABLE_FEATURES.VIEW_VESTING_SCHEDULES"
        >
          <v-list-item-title>
            <securities-action-modal
              :title="'View Vesting Schedule'"
              :option-details="optionDetails"
              :holders-list="holdersList"
              :is-vesting-available="isVestingAvailable"
              :max-width="1200"
              :action-type="CONSTS.ISSUED_SECURITY_ACTIONS.VESTING"
              @reload="$emit('reload')"
              @close="closeMenu"
            >
              <template v-slot:activator="{ on }">
                <a
                  v-on="{ ...on }"
                >
                  View Vesting Schedule
                </a>
              </template>
            </securities-action-modal>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <securities-action-modal
              :title="isSelectable ? 'View Draft': 'Options Summary'"
              :option-details="optionDetails"
              :max-width="1280"
              :share-terms-list="shareTermsList"
              :is-draft="isSelectable"
              :action-type="CONSTS.ISSUED_SECURITY_ACTIONS.VIEW"
              :holders-list="holdersList"
              :eip-list="eipList"
              @close="closeMenu"
              @reload="$emit('reload')"
              @issueHandler="$emit('issue')"
            >
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                >
                  {{ isSelectable ? 'View': 'View Summary' }}
                </a>
              </template>
            </securities-action-modal>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="!isSelectable"
          v-feature="CONSTS.CAPTABLE_FEATURES.RAISE_SECURITY_ACTION_REQUEST"
        >
          <v-list-item-title>
            <securities-action-modal
              :title="'Exercise Option'"
              :option-details="optionDetails"
              :action-type="CONSTS.ISSUED_SECURITY_ACTIONS.EXERCISE"
              :holders-list="holdersList"
              @reload="$emit('reload')"
              @close="closeMenu"
            >
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                >
                  Exercise
                </a>
              </template>
            </securities-action-modal>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="!isSelectable"
          v-feature="CONSTS.CAPTABLE_FEATURES.RAISE_SECURITY_ACTION_REQUEST"
        >
          <v-list-item-title>
            <securities-action-modal
              :title="'Terminate Option'"
              :option-details="optionDetails"
              :action-type="CONSTS.ISSUED_SECURITY_ACTIONS.TERMINATE"
              :holders-list="holdersList"
              @reload="$emit('reload')"
              @close="closeMenu"
            >
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                >
                  Terminate
                </a>
              </template>
            </securities-action-modal>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="!isSelectable"
          v-feature="CONSTS.CAPTABLE_FEATURES.RAISE_SECURITY_ACTION_REQUEST"
        >
          <v-list-item-title>
            <securities-action-modal
              :title="'Cancel Option'"
              :option-details="optionDetails"
              :action-type="CONSTS.ISSUED_SECURITY_ACTIONS.CANCEL"
              :holders-list="holdersList"
              @reload="$emit('reload')"
              @close="closeMenu"
            >
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                >
                  Cancel
                </a>
              </template>
            </securities-action-modal>
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-feature="CONSTS.CAPTABLE_FEATURES.EDIT_SECURITY">
          <v-list-item-title>
            <securities-action-modal
              :title="isSelectable ? 'Edit Options':'Modify Options'"
              :option-details="optionDetails"
              :share-terms-list="shareTermsList"
              :action-type="CONSTS.ISSUED_SECURITY_ACTIONS.MODIFY"
              :holders-list="holdersList"
              :eip-list="eipList"
              :is-draft="isSelectable"
              @close="closeMenu"
              @reload="$emit('reload')"
            >
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                >
                  {{ isSelectable ? 'Edit' : 'Modify' }}
                </a>
              </template>
            </securities-action-modal>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="isSelectable && optionDetails.is_valid"
          v-feature="CONSTS.CAPTABLE_FEATURES.ISSUE_SECURITY"
        >
          <v-list-item-title>
            <span
              class="primary--text cursor-pointer"
              @click="$emit('issue')"
            >
              Issue
            </span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-feature="CONSTS.CAPTABLE_FEATURES.DELETE_SECURITY">
          <v-list-item-title>
            <common-app-confirm
              title="Delete Option"
              message="Are you sure you want to delete this options?"
              yes-button="Yes"
              no-button="Cancel"
              @yes="deleteIssuedOption"
              @close="closeMenu"
            >
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                >
                  Delete
                </a>
              </template>
            </common-app-confirm>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  import SecuritiesActionModal from './SecuritiesActionModal'
  export default {
    name: 'OptionActions',

    components: {
      SecuritiesActionModal,
    },

    props: {
      optionDetails: {
        type: Object,
        required: true,
        default: () => {},
      },
      isSelectable: {
        type: Boolean,
        default: false,
      },
      holdersList: {
        type: Array,
        required: true,
        default: () => [],
      },
      shareTermsList: {
        type: Array,
        required: true,
      },
      eipList: {
        type: Array,
        required: true,
      },
      financingRoundList: {
        type: Array,
        required: true,
      },
    },

    computed: {
      isVestingAvailable () {
        return this.optionDetails.vest_actions.length > 0
      },
    },

    methods: {
      deleteIssuedOption () {
        this.$emit('delete')
      },
      closeMenu () {
        this.$refs.menu.isActive = false
      },
    },
  }
</script>
<style lang="scss" scoped>
 .v-list-item {
  min-height: 40px!important;
    &:hover {
      color: var(--v-primary-base) !important;
      background-color: rgba(0, 159, 70, 0.1)!important;
    }
 }
</style>
