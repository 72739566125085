<template>
  <v-container>
    <div class="text-center">
      <v-row>
        <v-col
          cols="12"
        >
          <search-bar
            v-model="search"
            :label="label"
          >
            <template v-slot:content>
              <v-row class="no-gutters">
                <v-col
                  cols="12"
                  md="4"
                  class="px-1"
                >
                  <common-input-select
                    v-model="filter.convertibleTerms"
                    label="Convertible Terms"
                    placeholder="Select Convertible Terms"
                    :items="convertibleTerms"
                    item-text="name"
                    item-value="id"
                    multiple
                    clearable
                  />
                </v-col>
                <v-col
                  v-if="showDraft"
                  cols="12"
                  md="4"
                  class="px-1"
                >
                  <common-input-select
                    v-model="filter.status"
                    label="Status"
                    placeholder="Status"
                    :items="CONSTS.DRAFTED_SECURITY_STATUS_LIST"
                    item-text="title"
                    item-value="value"
                    multiple
                    clearable
                  />
                </v-col>
                <v-col
                  v-if="!showDraft"
                  cols="12"
                  md="4"
                  class="px-1"
                >
                  <common-input-select
                    v-model="filter.financingRounds"
                    label="Financing Round"
                    placeholder="Select Financing Round"
                    :items="financingRounds"
                    item-text="name"
                    item-value="id"
                    multiple
                    clearable
                  />
                </v-col>
              </v-row>
            </template>
            <template v-slot:actions>
              <div
                v-if="showDraft"
                class="text-right"
              >
                <common-trs-btn
                  v-feature="CONSTS.CAPTABLE_FEATURES.ISSUE_SECURITY"
                  type="secondary"
                  medium
                  class="white--text mt-4"
                  :class="{ 'mr-3': $vuetify.breakpoint.smAndUp }"
                  text
                  :disabled="!selectedConvertibles || selectedConvertibles.length == 0"
                  @click="issueConvertibles"
                >
                  Issue Convertibles
                </common-trs-btn>
                <common-trs-btn
                  v-feature="CONSTS.CAPTABLE_FEATURES.DRAFT_SECURITY"
                  type="primary"
                  medium
                  class="white--text mt-4"
                  text
                  :to="{ name: 'DraftConvertible' }"
                >
                  <v-icon class="mr-2">
                    mdi-plus-circle
                  </v-icon>
                  Draft Convertible
                </common-trs-btn>
              </div>
            </template>
          </search-bar>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col>
          <convertibles-data-table
            :issueable-convertibles="issueableConvertibles"
            :convertible-terms="convertibleTerms"
            :share-terms-list="shareTermsList"
            :financing-rounds="financingRounds"
            :eips="eips"
            :investors="investors"
            :search="search"
            :loading="loading"
            :is-selectable="showDraft"
            :hide-fields="hideFields"
            :filter="filter"
            @convertiblesSelected="convertiblesSelected"
            @stopLoading="$emit('stopLoading')"
            @delete="deleteConvertible"
            @reload="$emit('reload')"
            @issue="issue"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
  import searchBar from '@/components/common/captable/components/Search'
  import ConvertiblesDataTable from './../data-tables/ConvertiblesDataTable'

  export default {
    name: 'WarranstTab',

    components: {
      searchBar,
      ConvertiblesDataTable,
    },

    props: {
      hideFields: {
        validate: () => true,
        default: () => [],
      },
      issueableConvertibles: {
        type: Array,
        required: true,
        default: () => [],
      },
      convertibleTerms: {
        type: Array,
        required: true,
        default: () => [],
      },
      financingRounds: {
        type: Array,
        required: true,
        default: () => [],
      },
      eips: {
        type: Array,
        required: true,
        default: () => [],
      },
      investors: {
        type: Array,
        required: true,
        default: () => [],
      },
      loading: {
        type: Boolean,
        default: false,
      },
      showDraft: {
        type: Boolean,
        default: false,
      },
      clearFilters: {
        type: Boolean,
        default: false,
      },
      shareTermsList: {
        type: Array,
        required: true,
      },
    },

    data: () => ({
      label: 'Search',
      search: '',
      selectedConvertibles: [],
      filter: {},
    }),

    watch: {
      clearFilters: {
        handler (val) {
          if (val) {
            this.filter = {}
            this.search = ''
          }
        },
        immediate: true,
      },
    },

    methods: {
      issueConvertibles () {
        this.$emit('issueConvertible', this.selectedConvertibles)
      },

      convertiblesSelected (val) {
        this.selectedConvertibles = val
      },
      deleteConvertible (val) {
        this.$emit('delete', val)
      },
      issue (val) {
        this.$emit('issue', val)
      },
    },
  }
</script>
