<template>
  <common-trs-page-container :padding="0">
    <div
      class="d-flex"
    >
      <common-trs-card class="mt-n4 pb-0 mb-2">
        <common-trs-page-nav-vertical
          v-model="currentTab"
        >
          <v-tab
            key="shares"
          >
            Shares
          </v-tab>
          <v-tab
            key="warrants"
          >
            Warrants
          </v-tab>
          <v-tab
            key="convertibles"
          >
            Convertibles
          </v-tab>
          <v-tab
            key="options"
          >
            Options
          </v-tab>
        </common-trs-page-nav-vertical>
      </common-trs-card>
      <div class="flex-fill mx-6 mx-md-8">
        <v-tabs-items
          v-model="currentTab"
          class="transparent-bg"
        >
          <v-tab-item
            key="shares"
            :eager="true"
          >
            <shares-tab
              :all-shares="issuedSharesData"
              :loading="loading"
              :holders-list="issuerProfileList"
              :share-terms-list="shareTermsList"
              :eip-list="eipList"
              :financing-round-list="financingRoundList"
              :show-draft="false"
              :hide-fields="['status']"
              :clear-filters="clearFilters"
              @stopLoading="stopLoading"
              @reload="reloadHandler('shared')"
              @delete="deleteSecurity"
            />
          </v-tab-item>
          <v-tab-item
            key="warrants"
            :eager="true"
          >
            <warrants-tab
              :issueable-warrants="issuedWarrants"
              :warrant-terms="warrantTermList"
              :share-terms-list="shareTermsList"
              :financing-rounds="financingRoundList"
              :eips="eipList"
              :investors="issuerProfileList"
              :loading="loading"
              :show-draft="false"
              :hide-fields="['status']"
              :clear-filters="clearFilters"
              @stopLoading="stopLoading"
              @reload="reloadHandler('warrant')"
              @delete="deleteSecurity"
            />
          </v-tab-item>
          <v-tab-item
            key="convertibles"
            :eager="true"
          >
            <convertibles-tab
              :issueable-convertibles="issuedConvertibles"
              :convertible-terms="convertibleTermList"
              :share-terms-list="shareTermsList"
              :financing-rounds="financingRoundList"
              :eips="eipList"
              :investors="issuerProfileList"
              :loading="loading"
              :show-draft="false"
              :hide-fields="['status']"
              :clear-filters="clearFilters"
              @stopLoading="stopLoading"
              @reload="reloadHandler('convertible')"
              @delete="deleteSecurity"
            />
          </v-tab-item>
          <v-tab-item
            key="options"
            :eager="true"
          >
            <options-tab
              :options-data="issuedOptionsList"
              :loading="loading"
              :financing-rounds="financingRoundList"
              :share-terms-list="shareTermsList"
              :eips="eipList"
              :investors="issuerProfileList"
              :show-draft="false"
              :hide-fields="['status']"
              :clear-filters="clearFilters"
              @stopLoading="stopLoading"
              @reload="reloadHandler('options')"
              @delete="deleteSecurity"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </common-trs-page-container>
</template>
<script>
  import WarrantsTab from './../components/tabs/WarrantsTab'
  import SharesTab from './../components/tabs/SharesTab'
  import OptionsTab from '../components/tabs/OptionsTab.vue'
  import ConvertiblesTab from './../components/tabs/ConvertiblesTab'

  import * as captableService from '@/components/common/captable/services/captable'
  import * as captableAdminService from '@/services/captable-admin-service'

  export default {
    name: 'IssuedSecuritiesIndex',

    components: {
      SharesTab,
      WarrantsTab,
      ConvertiblesTab,
      OptionsTab,
    },

    data: () => ({
      currentTab: 0,
      issuedSharesList: [],
      issuerProfileList: [],
      shareTermsList: [],
      issuedSharesData: [],
      loading: false,
      issuedWarrants: [],
      warrantTermList: [],
      issuedConvertibles: [],
      convertibleTermList: [],
      issuedOptionsList: [],
      eipList: [],
      financingRoundList: [],
      clearFilters: false,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      currentTab: {
        async handler (val) {
          this.loading = true
          if (val === 1 && (!this.issuedWarrants || this.issuedWarrants.length === 0)) {
            await this.loadIssuedWarrants()
          } else if (val === 2 && (!this.issuedConvertibles || this.issuedConvertibles.length === 0)) {
            await this.loadIssuedConvertibles()
          } else if (val === 3 && (!this.issuedOptionsList || this.issuedOptionsList.length === 0)) {
            await this.loadOptionsListData()
          }
          this.clearFilters = true
          setTimeout(() => {
            this.clearFilters = false
          }, 100)
          this.loading = false
        },
        immediate: true,
      },
    },

    async mounted () {
      this.loading = true
      await this.loadIssuerProfileList()
      await this.loadFinancingRoundList()
      await this.loadEipList()
      await this.loadShareTermList()
      await this.loadIssuedShareList()
      this.loading = false
    },

    methods: {
      async loadIssuedShareList () {
        try {
          const resp = await captableService.getIssuedShareList(this.captableId, true)
          this.issuedSharesData = resp?.data?.shares || []
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },

      async loadIssuerProfileList () {
        try {
          const resp = await captableService.getIssuerProfileList(this.captableId)
          this.issuerProfileList = resp?.data?.profiles || []
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },

      async loadShareTermList () {
        try {
          const resp = await captableService.getShareTermList(this.captableId)
          this.shareTermsList = resp.data.share_terms
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },

      async loadIssuedWarrants () {
        try {
          const resp = await captableService.getIssuedWarrantList(this.captableId, true)
          if (resp && resp.data) {
            this.issuedWarrants = resp.data.warrants
            this.loadAllWarrantTerms()
          }
        } catch (err) {
          this.loading = false
        }
      },

      async loadAllWarrantTerms (issuedWarrants) {
        const resp = await captableService.getWarrantTermList(this.captableId)
        if (resp && resp.data) {
          this.warrantTermList = resp.data.warrant_terms
        }
      },

      async loadIssuedConvertibles () {
        try {
          const resp = await captableService.getIssuedConvertibleList(this.captableId, true)
          if (resp && resp.data) {
            this.loadAllConvertibleTerms(resp.data.convertibles)
          }
        } catch (err) {
          this.loading = false
        }
      },

      async loadAllConvertibleTerms (issuedConvertibles) {
        const resp = await captableService.getConvertibleTermList(this.captableId)
        if (resp && resp.data) {
          this.convertibleTermList = resp.data.convertible_terms
        }
        this.getUpdatedConvertibleData(issuedConvertibles)
      },

      async loadFinancingRoundList () {
        try {
          const resp = await captableService.getAllFinancingRounds(this.captableId)
          if (resp && resp.data) {
            this.financingRoundList = resp.data.financing_rounds
          }
        } catch (e) {}
      },

      async loadEipList () {
        try {
          const resp = await captableService.getEipList(this.captableId)
          if (resp && resp.data) {
            this.eipList = resp.data.equity_incentive_plans
          }
        } catch (e) {}
      },

      getUpdatedConvertibleData (convertibles) {
        const updateConvertibleNameList = convertibles.map(c => ({ issuedConvertibleId: c.id, convertibleBoardApprovedOn: c.board_approved_on, ...c, ...this.convertibleTermList.find(c2 => c2.id === c.convertible_terms_id) }))
        const updateConvertibleName = updateConvertibleNameList.map(({ name: convertibleName, ...rest }) => ({ convertibleName, ...rest }))
        const updatedConvertibleHolders = updateConvertibleName.map(w => ({ ...w, ...this.issuerProfileList.find(p => p.id === w.holder_id) }))
        const updateHolderName = updatedConvertibleHolders.map(({ name: holderName, ...rest }) => ({ holderName, ...rest }))
        delete updateHolderName.id
        this.issuedConvertibles = updateHolderName
      },

      async loadOptionsListData () {
        try {
          const resp = await captableService.getOptionsList(this.captableId, true)
          this.issuedOptionsList = resp.data.options
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
          })
        }
      },

      stopLoading () {
        this.loading = false
      },

      async deleteSecurity (item) {
        try {
          this.loading = true
          const securityId = this.getSecurityId(item)
          const details = {
            payload: this.formData,
            certificatePayload: {
              captableId: this.captableId,
              securityId: securityId,
              termDocType: this.getSecurityTermType(item),
              securityDocType: this.getSecurityType(item),
              termID: item.share_terms_id,
              registrationName: item.registration_name,
              issuedNumber: item.number,
            },
          }
          await captableAdminService.createServiceRequest(this.getSecurityType(item), this.CONSTS.ISSUED_SECURITY_ACTIONS.DELETE, this.captableId, securityId, details)
          this.$store.dispatch('app/message', { text: captableAdminService.getServiceRequestSuccessMessage() })
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to delete security. Please try again later.',
          })
        }
      },
      getSecurityId (item) {
        switch (item.type) {
          case this.CONSTS.SHARE_TERM_TYPES.COMMON:
          case this.CONSTS.SHARE_TERM_TYPES.PREFERRED:
            return item.issuedShareId
          case this.CONSTS.SHARE_TERM_TYPES.WARRANT:
            return item.issuedWarrantId
          case this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE:
            return item.issuedConvertibleId
          case this.CONSTS.OPTIONS_TYPE.ISO :
          case this.CONSTS.OPTIONS_TYPE.NSO :
          case this.CONSTS.OPTIONS_TYPE.ISONSO:
            return item.issuedOptionId
        }
      },
      getSecurityTermType (item) {
        switch (item.type) {
          case this.CONSTS.SHARE_TERM_TYPES.COMMON:
          case this.CONSTS.SHARE_TERM_TYPES.PREFERRED:
            return this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARE_TERMS
          case this.CONSTS.SHARE_TERM_TYPES.WARRANT:
            return this.CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANT_TERMS
          case this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE:
            return this.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLE_TERMS
          case this.CONSTS.OPTIONS_TYPE.ISO :
          case this.CONSTS.OPTIONS_TYPE.NSO :
          case this.CONSTS.OPTIONS_TYPE.ISONSO:
            return this.CONSTS.NOTES_DOCUMENT_API_TYPE.EQUITY_INCENTIVE_PLANS
        }
      },
      getSecurityType (item) {
        switch (item.type) {
          case this.CONSTS.SHARE_TERM_TYPES.COMMON:
          case this.CONSTS.SHARE_TERM_TYPES.PREFERRED:
            return this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES
          case this.CONSTS.SHARE_TERM_TYPES.WARRANT:
            return this.CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANTS
          case this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE:
            return this.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES
          case this.CONSTS.OPTIONS_TYPE.ISO :
          case this.CONSTS.OPTIONS_TYPE.NSO :
          case this.CONSTS.OPTIONS_TYPE.ISONSO:
            return this.CONSTS.NOTES_DOCUMENT_API_TYPE.OPTIONS
        }
      },

      loadData (type) {
        switch (type) {
          case this.CONSTS.SHARE_TERM_TYPES.COMMON:
          case this.CONSTS.SHARE_TERM_TYPES.PREFERRED:
            this.loadIssuedShareList()
            break
          case this.CONSTS.SHARE_TERM_TYPES.WARRANT:
            this.loadIssuedWarrants()
            break
          case this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE:
            this.loadIssuedConvertibles()
            break
          case this.CONSTS.OPTIONS_TYPE.ISO :
          case this.CONSTS.OPTIONS_TYPE.NSO :
          case this.CONSTS.OPTIONS_TYPE.ISONSO:
            this.loadOptionsListData()
            break
        }
      },
      async  reloadHandler (type) {
        await this.loadIssuerProfileList()
        switch (type) {
          case 'shared':
            this.loadIssuedShareList()
            break
          case 'warrant':
            this.loadIssuedWarrants()
            break
          case 'convertible':
            this.loadIssuedConvertibles()
            break
          case 'options':
            this.loadOptionsListData()
            break
          default:
            break
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-tabs.v-tabs--vertical {
    min-width: 225px !important;
    height: 100%;
  }
  ::v-deep .v-tabs-items {
    background-color: transparent !important;
  }
  .v-tabs {
    padding-top: 20px!important;
    .v-tab {
      height: 41px;
      font-size: 0.875rem !important;
      font-weight: 400;
      margin: 5px 0px;
      &:hover {
        color: var(--v-primary-base) !important;
        background-color: rgba(0, 159, 70, 0.1)!important;
      }
      &.v-tab--active {
        font-weight: 600;
        background-color: rgba(0, 159, 70, 0.1)!important;
      }
    }
  }
</style>
