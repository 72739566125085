<template>
  <common-trs-filter-card
    class="mb-3 trs-filter-input"
  >
    <template v-slot:primary-filter="{ toggleExpanded, expanded }">
      <common-input-text-field
        v-model="internalValue"
        :label="label"
        color="primary"
        clearable
        hide-details
        single-line
        append-icon="mdi-magnify"
      >
        <template v-slot:append>
          <common-trs-filter-card-expand-btn
            :expanded="expanded"
            @toggleExpanded="toggleExpanded"
          />
        </template>
      </common-input-text-field>
    </template>
    <template v-slot:actions>
      <slot name="actions" />
    </template>

    <template v-slot:content>
      <slot name="content" />
    </template>
  </common-trs-filter-card>
</template>

<script>
  export default {
    name: 'Search',
    props: {
      value: {
        type: String,
        required: true,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
    },
    computed: {
      internalValue: {
        get () {
          return this.value || ''
        },
        set (val) {
          this.$emit('input', val || '')
        },
      },
    },

  }
  </script>
