<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ valid }"
      class="ml-auto"
    >
      <!-- Summary View Section -->
      <securities-summary-view
        :security-type="securityType"
        :security-details="securityDetails"
        :holder-details="holderDetails"
      />

      <!-- Cancel Date Section -->
      <v-row class="mt-8">
        <v-col
          cols="6"
          class="pr-0"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'No. of shares'"
            :rules="numOfSharesValidation"
          >
            <common-input-currency-input
              v-model="formData.number"
              label="No. of Shares"
              color="primary"
              :error-messages="errors"
              :hide-details="errors.length === 0"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="6"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            :name="'Cancelation date'"
          >
            <input-template-date
              v-model="cancellationDate"
              :mask="'##/##/####'"
              :input-templ="{label: 'Cancelation date', mandatory: true}"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              clearable
            />
          </validation-provider>
        </v-col>
      </v-row>

      <!-- Form Buttons Section -->
      <v-row>
        <v-col>
          <v-divider />
          <div class="mt-2 d-flex justify-space-between">
            <common-trs-btn
              type="secondary"
              medium
              class="mr-2"
              text
              @click="$emit('close')"
            >
              Cancel
            </common-trs-btn>
            <common-trs-btn
              type="primary"
              medium
              class="white--text"
              text
              :disabled="!valid"
              :loading="loading"
              @click="save"
            >
              Save
            </common-trs-btn>
          </div>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'
  import SecuritiesSummaryView from '@/components/common/captable/components/SecuritiesSummaryView'

  import * as captableAdminService from '@/services/captable-admin-service'

  export default {
    name: 'CancelShare',

    components: {
      SecuritiesSummaryView,
      InputTemplateDate,
    },

    props: {
      shareDetails: {
        type: Object,
        required: true,
        default: () => {},
      },
      holdersList: {
        type: Array,
        required: true,
        default: () => [],
      },
      isModalClosed: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      formData: {},
      loading: false,
      numOfSharesValidation: {},
      cancellationDate: '',
    }),

    computed: {
      securityType () {
        return this.shareDetails.type || this.CONSTS.SHARE_TERM_TYPES.COMMON
      },
      securityDetails () {
        return {
          name: this.shareDetails.shareName || '',
          numberOfSharesGranted: this.shareDetails.number || 0,
        }
      },
      holderDetails () {
        return {
          name: this.shareDetails.holderName || '',
          email: this.shareDetails.email || '',
          id: this.shareDetails.holderId || '',
        }
      },
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      shareDetails: {
        handler (val) {
          if (val) {
            this.numOfSharesValidation = {
              required: true,
              max_value: val.number,
              min_value: 0,
            }
          }
        },
        immediate: true,
      },
      isModalClosed: {
        handler (val) {
          if (val) {
            this.formData = {}
            val = false
            this.$refs.observer.reset()
          }
        },
        immediate: true,
      },
      cancellationDate: {
        handler (val) {
          if (val) {
            this.formData.canceled_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
    },

    methods: {
      async save () {
        this.loading = true
        if (this.formData.canceled_on > this.shareDetails.granted_on) {
          try {
            const details = {
              payload: this.formData,
              certificatePayload: {
                captableId: this.captableId,
                securityId: this.shareDetails.issuedShareId,
                termDocType: this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARE_TERMS,
                securityDocType: this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES,
                termID: this.shareDetails.share_terms_id,
                registrationName: this.shareDetails.registration_name,
                issuedNumber: this.formData.number,
                remainingNumber: this.shareDetails.number - this.formData.number,
              },
            }
            await captableAdminService.createServiceRequest(this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES, this.CONSTS.ISSUED_SECURITY_ACTIONS.CANCEL, this.captableId, this.shareDetails.issuedShareId, details)
            this.loading = false
            this.$emit('close')
            this.$emit('reload')
            this.$store.dispatch('app/message', { text: captableAdminService.getServiceRequestSuccessMessage() })
          } catch (err) {
            this.loading = false
            this.$store.dispatch('app/handleError', {
              err,
              message: 'Sorry! Unable to cancel your share. Please try again later.',
            })
          }
        } else {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            message: 'Sorry! Please select cancelation date greater than grant date.',
          })
        }
      },
    },
  }
</script>
