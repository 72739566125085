var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center",staticStyle:{"width":"50px"}},[_c('v-menu',{ref:"menu",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('common-trs-btn',_vm._g(_vm._b({staticClass:"mx-1 rounded-0",attrs:{"light":"","text":"","type":"secondary","icon":""}},'common-trs-btn',attrs,false),on),[_c('v-icon',{staticClass:"black--text"},[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('v-list',[(_vm.isVestingAvailable)?_c('v-list-item',{directives:[{name:"feature",rawName:"v-feature",value:(_vm.CONSTS.CAPTABLE_FEATURES.VIEW_VESTING_SCHEDULES),expression:"CONSTS.CAPTABLE_FEATURES.VIEW_VESTING_SCHEDULES"}]},[_c('v-list-item-title',[_c('securities-action-modal',{attrs:{"title":'View Vesting Schedule',"option-details":_vm.optionDetails,"holders-list":_vm.holdersList,"is-vesting-available":_vm.isVestingAvailable,"max-width":1200,"action-type":_vm.CONSTS.ISSUED_SECURITY_ACTIONS.VESTING},on:{"reload":function($event){return _vm.$emit('reload')},"close":_vm.closeMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({},Object.assign({}, on)),[_vm._v(" View Vesting Schedule ")])]}}],null,false,129007179)})],1)],1):_vm._e(),_c('v-list-item',[_c('v-list-item-title',[_c('securities-action-modal',{attrs:{"title":_vm.isSelectable ? 'View Draft': 'Options Summary',"option-details":_vm.optionDetails,"max-width":1280,"share-terms-list":_vm.shareTermsList,"is-draft":_vm.isSelectable,"action-type":_vm.CONSTS.ISSUED_SECURITY_ACTIONS.VIEW,"holders-list":_vm.holdersList,"eip-list":_vm.eipList},on:{"close":_vm.closeMenu,"reload":function($event){return _vm.$emit('reload')},"issueHandler":function($event){return _vm.$emit('issue')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.isSelectable ? 'View': 'View Summary')+" ")])]}}])})],1)],1),(!_vm.isSelectable)?_c('v-list-item',{directives:[{name:"feature",rawName:"v-feature",value:(_vm.CONSTS.CAPTABLE_FEATURES.RAISE_SECURITY_ACTION_REQUEST),expression:"CONSTS.CAPTABLE_FEATURES.RAISE_SECURITY_ACTION_REQUEST"}]},[_c('v-list-item-title',[_c('securities-action-modal',{attrs:{"title":'Exercise Option',"option-details":_vm.optionDetails,"action-type":_vm.CONSTS.ISSUED_SECURITY_ACTIONS.EXERCISE,"holders-list":_vm.holdersList},on:{"reload":function($event){return _vm.$emit('reload')},"close":_vm.closeMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({},on),[_vm._v(" Exercise ")])]}}],null,false,2760237641)})],1)],1):_vm._e(),(!_vm.isSelectable)?_c('v-list-item',{directives:[{name:"feature",rawName:"v-feature",value:(_vm.CONSTS.CAPTABLE_FEATURES.RAISE_SECURITY_ACTION_REQUEST),expression:"CONSTS.CAPTABLE_FEATURES.RAISE_SECURITY_ACTION_REQUEST"}]},[_c('v-list-item-title',[_c('securities-action-modal',{attrs:{"title":'Terminate Option',"option-details":_vm.optionDetails,"action-type":_vm.CONSTS.ISSUED_SECURITY_ACTIONS.TERMINATE,"holders-list":_vm.holdersList},on:{"reload":function($event){return _vm.$emit('reload')},"close":_vm.closeMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({},on),[_vm._v(" Terminate ")])]}}],null,false,1870137094)})],1)],1):_vm._e(),(!_vm.isSelectable)?_c('v-list-item',{directives:[{name:"feature",rawName:"v-feature",value:(_vm.CONSTS.CAPTABLE_FEATURES.RAISE_SECURITY_ACTION_REQUEST),expression:"CONSTS.CAPTABLE_FEATURES.RAISE_SECURITY_ACTION_REQUEST"}]},[_c('v-list-item-title',[_c('securities-action-modal',{attrs:{"title":'Cancel Option',"option-details":_vm.optionDetails,"action-type":_vm.CONSTS.ISSUED_SECURITY_ACTIONS.CANCEL,"holders-list":_vm.holdersList},on:{"reload":function($event){return _vm.$emit('reload')},"close":_vm.closeMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({},on),[_vm._v(" Cancel ")])]}}],null,false,3192033433)})],1)],1):_vm._e(),_c('v-list-item',{directives:[{name:"feature",rawName:"v-feature",value:(_vm.CONSTS.CAPTABLE_FEATURES.EDIT_SECURITY),expression:"CONSTS.CAPTABLE_FEATURES.EDIT_SECURITY"}]},[_c('v-list-item-title',[_c('securities-action-modal',{attrs:{"title":_vm.isSelectable ? 'Edit Options':'Modify Options',"option-details":_vm.optionDetails,"share-terms-list":_vm.shareTermsList,"action-type":_vm.CONSTS.ISSUED_SECURITY_ACTIONS.MODIFY,"holders-list":_vm.holdersList,"eip-list":_vm.eipList,"is-draft":_vm.isSelectable},on:{"close":_vm.closeMenu,"reload":function($event){return _vm.$emit('reload')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.isSelectable ? 'Edit' : 'Modify')+" ")])]}}])})],1)],1),(_vm.isSelectable && _vm.optionDetails.is_valid)?_c('v-list-item',{directives:[{name:"feature",rawName:"v-feature",value:(_vm.CONSTS.CAPTABLE_FEATURES.ISSUE_SECURITY),expression:"CONSTS.CAPTABLE_FEATURES.ISSUE_SECURITY"}]},[_c('v-list-item-title',[_c('span',{staticClass:"primary--text cursor-pointer",on:{"click":function($event){return _vm.$emit('issue')}}},[_vm._v(" Issue ")])])],1):_vm._e(),_c('v-list-item',{directives:[{name:"feature",rawName:"v-feature",value:(_vm.CONSTS.CAPTABLE_FEATURES.DELETE_SECURITY),expression:"CONSTS.CAPTABLE_FEATURES.DELETE_SECURITY"}]},[_c('v-list-item-title',[_c('common-app-confirm',{attrs:{"title":"Delete Option","message":"Are you sure you want to delete this options?","yes-button":"Yes","no-button":"Cancel"},on:{"yes":_vm.deleteIssuedOption,"close":_vm.closeMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({},on),[_vm._v(" Delete ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }