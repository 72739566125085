var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",staticClass:"ml-auto",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('securities-summary-view',{attrs:{"security-type":_vm.securityType,"security-details":_vm.securityDetails,"holder-details":_vm.holderDetails}}),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'No. of options',"rules":_vm.numOfOptionsValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"No. of Options","color":"primary","error-messages":errors,"hide-details":errors.length === 0},on:{"input":_vm.calculate},model:{value:(_vm.formData.number),callback:function ($$v) {_vm.$set(_vm.formData, "number", $$v)},expression:"formData.number"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":'Termination date'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"input-templ":{label: 'Termination date', mandatory: true},"error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.terminationDate),callback:function ($$v) {_vm.terminationDate=$$v},expression:"terminationDate"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Employee Terminated On'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"input-templ":{label: 'Employee terminated on', mandatory: true},"error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.employeeTerminatedOn),callback:function ($$v) {_vm.employeeTerminatedOn=$$v},expression:"employeeTerminatedOn"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',[_c('v-divider'),_c('div',{staticClass:"mt-2 d-flex justify-space-between"},[_c('common-trs-btn',{staticClass:"mr-2",attrs:{"type":"secondary","medium":"","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('common-trs-btn',{staticClass:"white--text",attrs:{"type":"primary","medium":"","text":"","disabled":!valid,"loading":_vm.loading},on:{"click":_vm.terminate}},[_vm._v(" Submit ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }