<template>
  <div>
    <common-trs-data-table
      v-model="selected"
      :headers="headers"
      :items="filteredHoldersData"
      item-key="issuedWarrantId"
      :loading="loading"
      hide-default-footer
      :show-select="isIssueAllowed && isSelectable"
      @toggle-select-all="selectAllToggle"
    >
      <!-- headers -->
      <template v-slot:[`header.warrantName`]="{ header }">
        <span class="trsText--text text-body-2 font-weight-bold"> {{ header.text }}</span>
      </template>
      <template v-slot:[`header.shareTermDetail`]="{ header }">
        <span>
          <div class="trsText--text text-body-2 font-weight-bold"> {{ header.text }}</div>
          <span class="trsText--text text-body-2 mt-1">
            {{ header.text2 }}
          </span>
        </span>
      </template>
      <template v-slot:[`header.holderName`]="{ header }">
        <span>
          <div class="trsText--text text-body-2 font-weight-bold"> {{ header.text }}</div>
          <span class="trsText--text text-body-2 mt-1">
            {{ header.text2 }}
          </span>
        </span>
      </template>
      <template v-slot:[`header.number`]="{ header }">
        <span class="trsText--text text-body-2 font-weight-bold"> {{ header.text }}</span>
      </template>
      <template v-slot:[`header.excercisePrice`]="{ header }">
        <span class="trsText--text text-body-2 font-weight-bold">
          {{ header.text }}
        </span>
      </template>
      <template v-slot:[`header.granted_on`]="{ header }">
        <span>
          <div class="trsText--text text-body-2 font-weight-bold"> {{ header.text }}</div>
          <span class="trsText--text text-body-2 mt-1">
            {{ header.text2 }}
          </span>
        </span>
      </template>
      <template
        v-slot:[`header.status`]="{ header }"
      >
        <span
          class="trsText--text text-body-2 font-weight-bold"
        > {{ header.text }}</span>
      </template>
      <template
        v-slot:[`header.action`]="{ header }"
      >
        <span
          class="trsText--text text-body-2 font-weight-bold"
        > {{ header.text }}</span>
      </template>
      <!-- table body -->
      <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected && item.is_valid"
          :readonly="!item.is_valid"
          :disabled="!item.is_valid"
          :ripple="false"
          @input="select($event)"
        />
      </template>
      <template v-slot:[`item.warrantName`]="{item}">
        <v-row class="trs--text text-body-1 no-gutters">
          <v-col
            cols="4"
            class="d-flex align-center"
          >
            <share-term-badge
              class="mx-1"
              :type="item.type"
              :show-single="true"
            />
          </v-col>
          <v-col>
            {{ item.warrantName }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.shareTermDetail`]="{item }">
        <span>
          <span class="trs--text text-body-1 text-capitalize">
            {{ item.shareTermName }}
          </span>
          <div class="trsText--text text-body-2 mt-1">
            {{ $date(item.shareTermBoardApprovedOn) }}
          </div>
        </span>
      </template>
      <template v-slot:[`item.holderName`]="{item }">
        <span>
          <span class="trs--text text-body-1">
            {{ item.holderName }}
          </span>
          <div class="trsText--text text-body-2 mt-1">
            {{ item.registration_name }}
          </div>
        </span>
      </template>
      <template v-slot:[`item.number`]="{ item}">
        <span class="trs--text text-body-1">
          {{ $number(item.number) }}
        </span>
      </template>
      <template v-slot:[`item.excercisePrice`]="{ item}">
        <span>
          <span class="trs--text text-body-1">
            {{ $currencyWithPrecision(item.excercisePrice) }}
          </span>
        </span>
      </template>
      <template v-slot:[`item.granted_on`]="{ item}">
        <span>
          <span class="trs--text text-body-1">
            {{ $date(item.granted_on ) }}
          </span>
          <div class="trsText--text text-body-2 mt-1">
            {{ $date(item.board_approved_on ) }}
          </div>
        </span>
      </template>
      <template v-slot:[`item.action`]="{item }">
        <warrants-actions
          :warrant-details="item"
          :holders-list="investors"
          :warrant-terms-list="warrantTerms"
          :eip-list="eips"
          :financing-round-list="financingRounds"
          :is-draft="isSelectable"
          @delete="$emit('delete',item)"
          @reload="$emit('reload')"
          @issue="$emit('issue',item)"
        />
      </template>
      <template
        v-slot:[`item.status`]="{item}"
      >
        <status-chip :is-valid="item.is_valid" />
      </template>
    </common-trs-data-table>
    <v-divider />
    <common-trs-data-table-list-pager
      v-model="pager"
      class="ml-2"
      :length="pages"
    />
  </div>
</template>
<script>
  import WarrantsActions from './../actions/warrants/WarrantsActions'
  import ShareTermBadge from '@/components/common/captable/components/ShareTermBadge'
  import StatusChip from '@/components/common/captable/components/StatusChip'
  import { getFeaturePermission } from '@/services/utils'

  const TABLE_HEADERS = [
    { text: 'Warrant Terms', value: 'warrantName', sortable: true, width: '200px' },
    { text: 'Linked Security', text2: '& Board Approval Date', value: 'shareTermDetail', sortable: true },
    { text: 'Holder Name', text2: '& Registration Name', value: 'holderName', sortable: true },
    { text: 'No. Of Warrants', value: 'number', sortable: true },
    { text: 'Exercise Price', value: 'excercisePrice', sortable: true },
    { text: 'Issue Date', text2: '& Board Approval Date', value: 'granted_on', sortable: true },
    { text: 'Status', value: 'status', sortable: true },
    { text: 'Actions', value: 'action', sortable: false },
  ]
  export default {
    name: 'WarrantsDataTable',

    components: {
      ShareTermBadge,
      WarrantsActions,
      StatusChip,
    },

    props: {
      hideFields: {
        validate: () => true,
        default: () => [],
      },
      issueableWarrants: {
        type: Array,
        required: true,
      },
      warrantTerms: {
        type: Array,
        required: true,
      },
      financingRounds: {
        type: Array,
        required: true,
      },
      eips: {
        type: Array,
        required: true,
      },
      investors: {
        type: Array,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      search: {
        type: String,
        default: '',
      },
      isSelectable: {
        type: Boolean,
        default: false,
      },
      filter: {
        type: Object,
        default: () => {},
      },
      shareTermsList: {
        type: Array,
        required: true,
      },
    },

    data: () => ({
      items: [],
      headers: [],
      pager: {
        itemsPerPage: 5,
        page: 1,
        total: 0,
      },
      updatedItems: [],
    }),

    computed: {
      pages () {
        return Math.ceil(this.updatedItems.length / this.pager.itemsPerPage) || 1
      },

      filteredHoldersData () {
        const clone = Object.assign([], this.updatedItems)
        const startFrom = (this.pager.page * this.pager.itemsPerPage) - this.pager.itemsPerPage
        return clone.splice(startFrom, this.pager.itemsPerPage)
      },

      selected: {
        get () {
          return []
        },
        set (val) {
          this.$emit('warrantsSelected', val)
        },
      },

      currentRoles () {
        return this.$store.getters['auth/roles']
      },

      isIssueAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.ISSUE_SECURITY, this.currentRoles)
      },
    },

    watch: {
      pager: {
        handler (val) {
          this.pager.total = this.pages
        },
        deep: true,
        immediate: true,
      },
      pages: {
        handler (val) {
          if (val) {
            this.pager.total = val
          }
        },
        deep: true,
        immediate: true,
      },
      warrantTerms: {
        handler (val) {
          if (val && val.length > 0) {
            this.getUpdatedWarrantData()
          }
        },
        immediate: true,
      },
      hideFields: {
        handler (val) {
          this.headers = TABLE_HEADERS.filter(header => val.indexOf(header.value) === -1)
        },
        immediate: true,
      },
      filter: {
        handler () {
          this.getUpdatedWarrantList()
        },
        immediate: true,
        deep: true,
      },
      search: {
        handler (val) {
          if (val) {
            const orgItems = JSON.parse(JSON.stringify(this.items))
            this.updatedItems = orgItems.filter((item) => {
              if (item.holderName.toLowerCase().indexOf(val.toLowerCase()) > -1) {
                return item
              }
            })
          } else {
            this.updatedItems = JSON.parse(JSON.stringify(this.items))
          }
        },
        immediate: true,
      },
    },

    methods: {
      getUpdatedWarrantData () {
        if (this.issueableWarrants && this.issueableWarrants.length > 0) {
          const updatedShareData = this.issueableWarrants.map(w => ({ issuedWarrantId: w.id, financingRound: w.financing_round_id, excercisePrice: w.strike_price, warrantBoardApprovedOn: w.board_approved_on, ...w, ...this.shareTermsList.find(s => s.id === w.share_terms_id) }))
          const updateShareData = updatedShareData.map(({ name: shareTermName, board_approved_on: shareTermBoardApprovedOn, id: shareTermId, type: shareTermType, ...rest }) => ({ shareTermName, shareTermBoardApprovedOn, shareTermId, shareTermType, ...rest }))

          const updateWarrantNameList = updateShareData.map(w => ({ issuedWarrantId: w.id, type: this.CONSTS.SHARE_TERM_TYPES.WARRANT, financingRound: w.financing_round_id, excercisePrice: w.strike_price, warrantBoardApprovedOn: w.board_approved_on, ...w, ...this.warrantTerms.find(w2 => w2.id === w.warrant_terms_id) }))
          const updateWarrantName = updateWarrantNameList.map(({ name: warrantName, ...rest }) => ({ warrantName, ...rest }))

          const updatedWarrantHolders = updateWarrantName.map(w => ({ ...w, ...this.investors.find(p => p.id === w.holder_id) }))
          const updateHolderName = updatedWarrantHolders.map(({ name: holderName, ...rest }) => ({ holderName, ...rest }))
          delete updateHolderName.id
          updateHolderName.forEach((share) => {
            share.board_approved_on = share.warrantBoardApprovedOn
            share.financing_round_id = share.financingRound
            delete share.financingRound
            delete share.warrantBoardApprovedOn
          })
          this.items = updateHolderName
        } else {
          this.items = []
        }
        this.getUpdatedWarrantList()
        this.$emit('stopLoading')
      },
      getUpdatedWarrantList () {
        const orgItems = JSON.parse(JSON.stringify(this.items))
        let isWarrantFilterSelected = false
        let items = orgItems
        let paramItems = null
        if (this.filter && Object.keys(this.filter).length > 0) {
          if (this.filter.warrantTerms && this.filter.warrantTerms.length > 0) {
            items = this.getWarrantTermFilteredData(orgItems)
            isWarrantFilterSelected = true
          }
          if (isWarrantFilterSelected) {
            paramItems = JSON.parse(JSON.stringify(items))
          } else {
            paramItems = JSON.parse(JSON.stringify(orgItems))
          }
          if (this.filter.financingRounds && this.filter.financingRounds.length > 0) {
            items = this.getRoundFilteredData(paramItems)
          }
          if (this.filter.status && this.filter.status.length > 0) {
            items = this.getStatusFilteredData(paramItems)
          }
        }
        this.updatedItems = items
      },
      getWarrantTermFilteredData (orgItems) {
        let items = []
        this.filter.warrantTerms.forEach((id) => {
          const data = orgItems.filter((item) => {
            if (item.warrant_terms_id === id) {
              return item
            }
          })
          items = [...new Set(items.concat(data))]
        })
        return items
      },
      getRoundFilteredData (orgItems) {
        let items = []
        this.filter.financingRounds.forEach((id) => {
          const data = orgItems.filter((item) => {
            if (item.financing_round_id === id) {
              return item
            }
          })
          items = [...new Set(items.concat(data))]
        })
        return items
      },
      getStatusFilteredData (orgItems) {
        let items = []
        this.filter.status.forEach((status) => {
          const data = orgItems.filter((item) => {
            if ((status === this.CONSTS.DRAFTED_SECURITY_STATUS.VALID && item.is_valid) || (status === this.CONSTS.DRAFTED_SECURITY_STATUS.INVALID && !item.is_valid)) {
              return item
            }
          })
          items = [...new Set(items.concat(data))]
        })
        return items
      },
      selectAllToggle (props) {
        if (this.selected.length !== this.filteredData.length - this.disabledCount) {
          this.selected = []
          props.items.forEach(item => {
            if (item.is_valid) {
              this.selected.push(item)
              item.selected = true
            } else {
              item.selected = false
            }
          })
        } else this.selected = []
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .v-data-table > .v-data-table__wrapper > table {
    thead {
      tr {
        th {
          vertical-align: top;
        }
      }
    }
    tbody {
      tr {
        height: 70px !important;
        td {
          vertical-align: middle;
          padding: 8px!important;
        }
      }
    }
  }
</style>
