var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",staticClass:"ml-auto",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('holders-selection-form',{attrs:{"title":'Select Holder',"holders-list":_vm.holdersList},on:{"update":_vm.updateHolderId},model:{value:(_vm.holder),callback:function ($$v) {_vm.holder=$$v},expression:"holder"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Warrant term',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Warrant Term","placeholder":"Select warrant term","items":_vm.warrantTermsList,"item-text":"name","item-value":"id","error-messages":errors},on:{"change":_vm.setPrefilledData},model:{value:(_vm.formData.warrant_terms_id),callback:function ($$v) {_vm.$set(_vm.formData, "warrant_terms_id", $$v)},expression:"formData.warrant_terms_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Financing round',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Financing Round","placeholder":"Select financing round","items":_vm.financingRoundList,"item-text":"name","item-value":"id","error-messages":errors},model:{value:(_vm.formData.financing_round_id),callback:function ($$v) {_vm.$set(_vm.formData, "financing_round_id", $$v)},expression:"formData.financing_round_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Granted warrants',"rules":{required: true, min_value: 0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Granted warrants","color":"primary","error-messages":errors},model:{value:(_vm.formData.number),callback:function ($$v) {_vm.$set(_vm.formData, "number", $$v)},expression:"formData.number"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Exercise price per share',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Exercise price per share","color":"primary","error-messages":errors,"options":{ locale: 'en-US', precision: 2 },"prepend-inner-icon":"mdi-currency-usd"},model:{value:(_vm.formData.excercisePrice),callback:function ($$v) {_vm.$set(_vm.formData, "excercisePrice", $$v)},expression:"formData.excercisePrice"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":'Grant date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"input-templ":{label: 'Grant date', mandatory: true},"error-messages":errors,"clearable":""},model:{value:(_vm.grantedOn),callback:function ($$v) {_vm.grantedOn=$$v},expression:"grantedOn"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":'Expiration date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"input-templ":{label: 'Expiration date', mandatory: true},"error-messages":errors,"clearable":""},model:{value:(_vm.expiresOn),callback:function ($$v) {_vm.expiresOn=$$v},expression:"expiresOn"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":'Board approval date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"input-templ":{label: 'Board approval date', mandatory: true},"error-messages":errors,"clearable":""},model:{value:(_vm.boardApprovedOn),callback:function ($$v) {_vm.boardApprovedOn=$$v},expression:"boardApprovedOn"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":'Stock registration name',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Stock Registration Name","color":"primary","error-messages":errors},model:{value:(_vm.stockRegistrationName),callback:function ($$v) {_vm.stockRegistrationName=$$v},expression:"stockRegistrationName"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('notes-and-documents-form',{attrs:{"id":_vm.warrantDetails.issuedWarrantId,"type":_vm.CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANTS,"internal-dialog":_vm.internalDialog,"mode":'edit',"is-submit":_vm.isSubmit},on:{"navToList":_vm.navToList}})],1)],1),_c('v-row',[_c('v-col',[_c('v-divider'),_c('div',{staticClass:"mt-2 d-flex justify-space-between"},[_c('common-trs-btn',{staticClass:"mr-2",attrs:{"type":"secondary","medium":"","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('common-trs-btn',{staticClass:"white--text",attrs:{"type":"primary","medium":"","text":"","disabled":!valid,"loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }