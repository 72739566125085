<template>
  <v-dialog
    v-model="internalDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :max-width="maxWidth"
    scrollable
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:activator="{ on }">
      <slot
        name="activator"
        v-bind="{ on }"
      />
    </template>
    <common-trs-modal-card>
      <common-trs-modal-card-title>
        <common-trs-modal-card-title-content>
          {{ title }}
        </common-trs-modal-card-title-content>

        <common-trs-btn
          aria-label="Close"
          type="secondary"
          class="rounded-0"
          color="primary"
          size="large"
          icon
          text
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </common-trs-btn>
      </common-trs-modal-card-title>
      <common-trs-modal-card-text>
        <modify-warrant
          v-if="actionType == CONSTS.ISSUED_SECURITY_ACTIONS.MODIFY"
          :warrant-details="warrantDetails"
          :holders-list="holdersList"
          :warrant-terms-list="warrantTermsList"
          :internal-dialog="internalDialog"
          :eip-list="eipList"
          :financing-round-list="financingRoundList"
          :is-draft="isDraft"
          @close="close"
          @reload="$emit('reload')"
        />
        <exercise-warrant
          v-if="actionType == CONSTS.ISSUED_SECURITY_ACTIONS.EXERCISE"
          :warrant-details="warrantDetails"
          :is-modal-closed="isModalClosed"
          @close="close"
          @reload="$emit('reload')"
        />
        <cancel-warrant
          v-if="actionType == CONSTS.ISSUED_SECURITY_ACTIONS.CANCEL"
          :warrant-details="warrantDetails"
          :is-modal-closed="isModalClosed"
          @close="close"
          @reload="$emit('reload')"
        />
        <view-modal
          v-if="actionType == CONSTS.ISSUED_SECURITY_ACTIONS.VIEW"
          :id="warrantDetails.issuedWarrantId"
          :details="warrantDetails"
          :holders-list="holdersList"
          :internal-dialog="internalDialog"
          :terms-list="warrantTermsList"
          :type="CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANTS"
          :is-modal-closed="isModalClosed"
          :is-draft="isDraft"
          @close="close"
          @issueHandler="$emit('issueHandler')"
          @reload="$emit('reload')"
        />
      </common-trs-modal-card-text>
    </common-trs-modal-card>
  </v-dialog>
</template>
<script>
  import ModifyWarrant from './ModifyWarrant'
  import ExerciseWarrant from './ExerciseWarrant'
  import CancelWarrant from './CancelWarrant'
  import ViewModal from '../../ViewModal'

  export default {
    name: 'SecuritiesActionModal',

    components: {
      ModifyWarrant,
      ExerciseWarrant,
      CancelWarrant,
      ViewModal,
    },

    props: {
      title: {
        type: String,
      },
      warrantDetails: {
        type: Object,
        required: true,
        default: () => {},
      },
      holdersList: {
        type: Array,
        required: true,
        default: () => [],
      },
      actionType: {
        type: String,
        required: true,
      },
      warrantTermsList: {
        type: Array,
        default: () => [],
      },
      eipList: {
        type: Array,
        default: () => [],
      },
      financingRoundList: {
        type: Array,
        default: () => [],
      },
      maxWidth: {
        type: Number,
        default: 700,
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      internalDialog: false,
      isModalClosed: false,
    }),

    watch: {
      internalDialog: {
        handler (newVal, oldVal) {
          if (oldVal && !newVal) {
            this.isModalClosed = true
            this.$emit('close')
          }
        },
        immediate: true,
      },
    },

    methods: {
      close () {
        this.internalDialog = false
        this.$emit('close')
      },
    },
  }
</script>
